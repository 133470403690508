import { ReactElement } from 'react'
import { Spacer } from '../Common'

interface SidebarListSubitemProps {
    /**
     * List item text
     */
    children: string
    /**
     * List item badge
     */
    badge?: JSX.Element
    /**
     * List item active state
     */
    active?: boolean
    /**
     * Optional click event
     */
    onClick?: () => void
}

export const SidebarListSubitem = ({
    children,
    badge,
    active = false,
    onClick
}: SidebarListSubitemProps): ReactElement => {
    const handleClick = () => {
        if (!onClick) {
            return
        }

        onClick()
    }

    return (
        <li data-testid="sidebar_list_subitem_component">
            <a
                className={`flex pl-11 py-1.5 text-base items-center px-1 cursor-pointer font-medium hover:bg-primary-100 rounded-lg ${
                    active ? 'text-primary-600' : 'text-neutrals-800'
                }`}
                role="button"
                onClick={() => handleClick()}
                data-testid="sidebar_list_subitem_component_button">
                <span>{children}</span>
                <Spacer></Spacer>
                {!!badge && <span className="mr-1">{badge}</span>}
            </a>
        </li>
    )
}
