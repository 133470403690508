import { CSSProperties, useMemo, useRef } from 'react'

interface DropdownProps {
    parentRef: React.RefObject<HTMLDivElement | null>
    /**
     * Dropdown parent focus state
     */
    isFocus?: boolean
    /**
     * Min width of dropdown
     */
    dropdownMinWidth?: number
    /**
     * Prepend element to dropdown
     */
    prepend?: JSX.Element
    /**
     * Append element to dropdown
     */
    append?: JSX.Element
    /**
     * List of dropdown items
     */
    children: JSX.Element | JSX.Element[]
    /**
     * Maximum height
     */
    maxHeight?: number
    /**
     * Position
     */
    position?: 'left' | 'right'
    /**
     * Anchor
     */
    anchor?: 'top' | 'bottom'
    /**
     * Auto close when select
     */
    autoClose?: boolean
}

export const Dropdown = ({
    parentRef,
    isFocus = false,
    dropdownMinWidth,
    prepend,
    append,
    children,
    maxHeight = 300,
    position = 'left',
    anchor = 'bottom',
    autoClose = true
}: DropdownProps) => {
    const dropdownRef = useRef<HTMLDivElement | null>(null)
    const style = useMemo(() => {
        const _style: CSSProperties = {
            maxHeight,
            minWidth: dropdownMinWidth || 0,
            width: 200,
            position: 'fixed'
        }

        if (!parentRef?.current || !dropdownRef?.current) {
            return _style
        }

        _style.width = parentRef.current.offsetWidth

        const parentRect = parentRef.current.getBoundingClientRect()

        _style.top = parentRect.bottom
        _style.left = parentRect.left

        if (anchor === 'top') {
            _style.top = parentRect.top - dropdownRef.current.offsetHeight - 10
        }

        if (position === 'right') {
            _style.left = undefined
            _style.right = parentRect.right - parentRef.current.offsetWidth
        }

        return _style
    }, [parentRef.current, dropdownRef.current, anchor, position])

    return (
        <div
            ref={dropdownRef}
            className={`flex ${
                anchor === 'bottom' ? 'shadow-lg' : 'border'
            } w-full mt-2 rounded-lg overflow-hidden bg-white transition-opacity ease-linear ${
                isFocus ? 'opacity-100 z-10 mb-1' : 'opacity-0 -z-50'
            } flex-col`}
            style={style}
            onClick={(e) => {
                if (autoClose) {
                    e.stopPropagation()
                }
            }}>
            {prepend}
            <div className="overflow-y-auto flex-grow">{children}</div>
            {append}
        </div>
    )
}
