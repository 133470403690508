import jwt_decode from 'jwt-decode'
import { AccessToken } from '~/types'
import { localStore } from './local-store'

interface FormatNumberToCurrencyStringOption {
    roundType: 'round' | 'ceil' | 'floor'
    round: boolean
}

export const formatNumberToCurrencyString = (
    num = 0,
    options: FormatNumberToCurrencyStringOption = {
        roundType: 'round',
        round: true
    }
) => {
    const { roundType, round } = options
    let value = num

    if (round) {
        value = Math[roundType]((num + Number.EPSILON) * 100) / 100
    }

    return String(value)
        .replace(/\./g, ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export const isNumberOnly = (number: string) => {
    const numberRegx = /^\d+$/
    return number.match(numberRegx)
}

export const delayedCallback = (callback: () => void, timeout = 150) => {
    setTimeout(() => {
        callback()
    }, timeout)
}

export const padTime = (time: number) => {
    return String(time).length === 1 ? `0${time}` : `${time}`
}

export const formatTime = (time: number) => {
    // Convert seconds into minutes and take the whole part
    const minutes = Math.floor(time / 60)

    // Get the seconds left after converting minutes
    const seconds = time % 60

    //Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`
}

export const convertToNumber = (value: string) => {
    return Number(value.replace(/\D/g, '').replace(/\b0+/g, ''))
}

export const SkuIDGenerator = () => {
    const random = Math.floor(1000000000 + Math.random() * 9000000000).toString()
    return '123' + random
}

export const GetCustomerShortNames = (name: string) => {
    if (!name) {
        return '-'
    }

    return name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '')
        .toUpperCase()
        .substring(0, 3)
}

export const getCurrentBusiness = () => {
    const business = localStore.get('SELECTED_BUSINESS')?.data

    if (!business) {
        return ''
    }

    return business
}

export const setCurrentBusiness = (businessId: string) => {
    localStore.set('SELECTED_BUSINESS', businessId)
}

export const unsetCurrentBusiness = () => {
    localStore.remove('SELECTED_BUSINESS')
}

export const getAccessToken = (token?: string): AccessToken | null => {
    const accessToken = !token ? localStore.get('ACCESS_TOKEN')?.data : token

    try {
        return jwt_decode(accessToken!)
    } catch {
        return null
    }
}

export const difference = (a: number, b: number) => {
    return Math.abs(a - b)
}

export const isElementInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect()

    return isInViewport(rect.top, rect.right, rect.bottom, rect.left)
}

export const isInViewport = (_top: number, _right: number, _bottom: number, _left: number) => {
    const top = _top >= 0
    const right = _right <= (window.innerWidth || document.documentElement.clientWidth)
    const bottom = _bottom <= (window.innerHeight || document.documentElement.clientHeight)
    const left = _left >= 0

    return {
        top,
        right,
        bottom,
        left,
        all: top && left && bottom && right
    }
}

type MultiPromiseResolver = <T>(promises: Promise<any>[]) => Promise<{ status: string; value?: T; reason?: any }[]>
export const multiPromiseResolver: MultiPromiseResolver = (promises) => {
    return Promise.all(
        promises.map((promise) => {
            return promise
                .then((data) => {
                    return { status: 'fulfilled', value: data }
                })
                .catch((error) => {
                    return { status: 'rejected', reason: error }
                })
        })
    )
}

export const replaceToPhoneNumberFormat = (text: string) => {
    return text.replace(/[^0-9+]/g, '').replace(/\b0+/g, '')
}

export const countryCodeRegex = /^(?!(?:62|97))/g
