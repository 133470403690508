import { responsiveBreakpointMap } from '~/constants'
import { DesktopPrompt } from '../DesktopPrompt'

interface AuthLayoutProps {
    children: JSX.Element | JSX.Element[]
    showMobile?: boolean
}

export const AuthLayout = ({ children, showMobile }: AuthLayoutProps) => {
    if (window.innerWidth < responsiveBreakpointMap.md && !showMobile) {
        return (
            <>
                <DesktopPrompt></DesktopPrompt>
            </>
        )
    }

    return (
        <>
            <main className="w-full h-full flex justify-center items-center overflow-auto p-5">
                <div className="max-h-full w-full">{children}</div>
            </main>
        </>
    )
}
