import React from 'react'
import { Icon } from '../Icon'

interface PaginationProps {
    /**
     * Total of records
     */
    total: number
    /**
     * Current active page
     */
    current?: number
    /**
     * Total items per page
     */
    itemPerPage?: number
    /**
     * Range between current active page to elipsis
     */
    rangeToElipsis?: number
    /**
     * Overwrite active text color
     */
    activeTextColor?: string
    /**
     * Overwrite active background color
     */
    activeBackgroundColor?: string
    /**
     * Hide/show arrow
     */
    arrow?: boolean
    /**
     * Previous button label
     */
    previousLabel?: string
    /**
     * Next button label
     */
    nextLabel?: string
    /**
     * Hide/show page numbers
     */
    pageNumbers?: boolean
    /**
     * Optional event on page select
     */
    onSelect?: (current: number) => void
}

export const Pagination = ({
    total,
    current = 1,
    itemPerPage = 10,
    rangeToElipsis = 2,
    activeTextColor,
    activeBackgroundColor,
    arrow = true,
    previousLabel = 'Previous',
    nextLabel = 'Next',
    pageNumbers = true,
    onSelect
}: PaginationProps) => {
    const numberOfPages = Math.ceil(total / itemPerPage)

    const onPageSelect = (pageNumber: number) => {
        if (pageNumber === current) {
            return
        }

        if (!onSelect) {
            return
        }

        onSelect(pageNumber)
    }

    const pages = getPages(current, numberOfPages, rangeToElipsis)

    return (
        <nav aria-label="Page navigation">
            <ul className="inline-flex -space-x-px">
                <li>
                    <a
                        role="button"
                        className="inline-flex items-center text-neutrals-500 bg-white border border-gray-300 ml-0 rounded-l-lg leading-normal text-sm font-medium py-1.5 px-3 hover:text-primary-600 hover:bg-primary-100 h-full"
                        onClick={() => {
                            if (current !== 1) onPageSelect(current - 1)
                        }}>
                        {arrow ? (
                            <>
                                <span className="sr-only">{previousLabel}</span>
                                <Icon name="chevron-left" size="lg"></Icon>
                            </>
                        ) : (
                            <span className={!pageNumbers ? 'w-14 text-center inline-block' : ''}>{previousLabel}</span>
                        )}
                    </a>
                </li>
                {pageNumbers &&
                    pages.map((item, index) => (
                        <li key={index}>
                            <a
                                role="button"
                                className={`no-underline block leading-normal text-sm font-medium border border-neutrals-300 py-1.5 text-center w-8 hover:text-primary-600 hover:bg-primary-100 h-full ${
                                    item === current ? 'text-primary-600 bg-primary-100' : 'text-neutrals-500 bg-white'
                                }`}
                                style={
                                    item === current
                                        ? {
                                              color: activeTextColor,
                                              backgroundColor: activeBackgroundColor
                                          }
                                        : {}
                                }
                                onClick={() => {
                                    if (typeof item === 'number') onPageSelect(item)
                                }}>
                                {item}
                            </a>
                        </li>
                    ))}
                <li>
                    <a
                        role="button"
                        className="inline-flex items-center text-neutrals-500 bg-white border border-gray-300 ml-0 rounded-r-lg leading-normal text-sm font-medium py-1.5 px-3 hover:text-primary-600 hover:bg-primary-100 h-full"
                        onClick={() => {
                            if (current !== numberOfPages) onPageSelect(current + 1)
                        }}>
                        {arrow ? (
                            <>
                                <span className="sr-only">{nextLabel}</span>
                                <Icon name="chevron-right" size="lg"></Icon>
                            </>
                        ) : (
                            <span className={!pageNumbers ? 'w-14 text-center inline-block' : ''}>{nextLabel}</span>
                        )}
                    </a>
                </li>
            </ul>
        </nav>
    )
}

const getPages = (current: number, numberOfPages: number, rangeToElipsis: number) => {
    const left = current - rangeToElipsis
    const right = current + rangeToElipsis
    const pages = []
    const pagesWithDots = []

    for (let i = 1; i <= numberOfPages; i++) {
        if (i === 1 || i === numberOfPages || (i >= left && i <= right)) {
            pages.push(i)
        }
    }

    let prevNode

    for (const page of pages) {
        if (prevNode) {
            if (page - prevNode === 2) {
                pagesWithDots.push(prevNode + 1)
            } else if (page - prevNode !== 1) {
                pagesWithDots.push('...')
            }
        }

        pagesWithDots.push(page)
        prevNode = page
    }

    return pagesWithDots
}
