import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, TextField } from '~/components'
import { formatTime } from '~/utils'
import { PhoneNumber } from './PhoneNumberForm'

export type OTPChannel = 'WA' | 'SMS'

export interface OTPFormProps {
    /**
     * OTP timer
     */
    OTPTimer?: number
    /**
     * on OTP header note
     */
    OTPHeader?: string | JSX.Element
    /**
     * OTP button text
     */
    OTPButtonText?: string
    /**
     * on OTP footer note
     */
    OTPFooter?: string | JSX.Element
    /**
     *  OTP error
     */
    OTPError?: string
    /**
     * default channel
     */
    channel?: OTPChannel
    /**
     * Show alternative channel option
     */
    showAlternative?: boolean
    /**
     * Write otp programatically for auto fill
     */
    writeOTP?: string
    /**
     * form loading state
     */
    OTPFormLoading?: boolean
    /**
     * enable/disable edit phone number
     */
    editPhoneNumber?: boolean
    /**
     * resend OTP handler
     */
    onResendOTP?: (value: PhoneNumber) => void
    /**
     * handler on phone number change
     */
    onClickChangeNumber?: (value: PhoneNumber) => void
    /**
     * handler for send in alternative
     */
    onSendWithAlternative?: (value: PhoneNumber, channel: OTPChannel) => void
    /**
     * on input OTP event
     */
    onInputOTP?: (value: string) => void
    /**
     * handler for OTP submit
     */
    onSubmitOTP?: (value: PhoneNumber & { OTP: string }) => void
    /**
     * handler for OTP error
     */
    onOTPError?: (value: string) => void
}

type OTPFormPropsType = OTPFormProps &
    PhoneNumber & {
        /**
         * Handle login step
         */
        setStep: Dispatch<SetStateAction<number>>
    }

export const OTPForm = ({
    countryCode,
    phoneNumber,
    OTPTimer = 30,
    OTPHeader,
    OTPButtonText = 'Verifikasi',
    OTPFooter,
    OTPError = '',
    channel = 'WA',
    showAlternative = true,
    writeOTP,
    OTPFormLoading,
    editPhoneNumber = true,
    onResendOTP,
    onClickChangeNumber,
    onSendWithAlternative,
    onSubmitOTP,
    onInputOTP,
    onOTPError = () => false,
    setStep
}: OTPFormPropsType) => {
    const [OTP, setOTP] = useState('')
    const [counter, setCounter] = useState(OTPTimer)

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>

        if (counter > 0) {
            timer = setTimeout(() => setCounter((c) => c - 1), 1000)
        }

        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [counter])

    useEffect(() => {
        setOTP(writeOTP || '')
    }, [writeOTP])

    const handleResend = () => {
        if (counter > 0) {
            return
        }

        setCounter(30)

        if (onResendOTP) {
            onResendOTP({ countryCode, phoneNumber })
        }
    }

    const renderShowCounter = () => {
        return (
            <div data-testid="resend_code" className="text-sm mt-4">
                <span className="text-neutrals-900">Belum terima kode? </span>
                {counter != 0 ? (
                    <span className="font-normal text-neutrals-900">
                        tunggu <span className="text-primary-600 font-bold">{formatTime(counter)}</span>
                    </span>
                ) : (
                    <span className="text-primary-600 font-bold cursor-pointer resend" onClick={() => handleResend()}>
                        Kirim ulang kode
                    </span>
                )}
            </div>
        )
    }

    const handleInputOTP = (value: string) => {
        setOTP(value)
        onOTPError('')

        if (onInputOTP) {
            onInputOTP(value)
        }
    }

    const handleClickChangeNumber = () => {
        if (counter > 0) {
            return
        }

        setStep(1)

        if (onClickChangeNumber) {
            onClickChangeNumber({
                countryCode,
                phoneNumber
            })
        }
    }

    const handleSubmitOTP = () => {
        if (onSubmitOTP) {
            onSubmitOTP({ countryCode, phoneNumber, OTP })
        }
    }

    return (
        <section className="flex flex-col items-center text-sm text-center w-full">
            <div className="flex flex-col items-center mb-5">
                <div className="font-normal text-sm text-neutrals-900 mb-3">
                    {OTPHeader ? OTPHeader : <p>Kami telah mengirimkan kode melalui</p>}
                    <p>
                        {channel === 'SMS' ? 'SMS' : 'WhatsApp'} ke{' '}
                        <span className="font-bold">
                            {countryCode} {phoneNumber}
                        </span>
                    </p>
                </div>

                {editPhoneNumber && (
                    <span
                        role="button"
                        data-testid="change_no"
                        className={`font-bold ${
                            counter !== 0 ? 'text-neutrals-300' : 'text-primary-600 cursor-pointer'
                        }`}
                        onClick={() => handleClickChangeNumber()}>
                        ubah nomor disini
                    </span>
                )}
            </div>

            <div className="flex w-full items-center">
                <TextField
                    className="w-full"
                    textAlign="center"
                    size="lg"
                    value={OTP}
                    fullWidthInput
                    placeholder="&#x25CF; &#x25CF; &#x25CF; &#x25CF; &#x25CF; &#x25CF;"
                    letterSpacing="widest"
                    fontWeight="bold"
                    maxLength={6}
                    onInput={(value) => {
                        const val = value.replace(/\D/g, '')
                        handleInputOTP(val)
                    }}
                    onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        if (event.key === 'Enter') {
                            handleSubmitOTP()
                        }
                    }}></TextField>
                {/* <OTPInput
          numInputs={6}
          value={OTP}
          placeholder="------"
          isInputNum
          containerStyle="grid grid-cols-4 gap-4 w-full"
          inputStyle="border border-gray-400 w-full rounded-lg p-2 text-center text-lg font-medium"
          focusStyle="outline-primary-600 focus ring-1 outline"
          onChange={(value: string) => handleInputOTP(value)}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              handleSubmitOTP()
            }
          }}
        ></OTPInput> */}
            </div>

            {OTPError.length > 0 && <div className="text-red-80 text-sm mt-2">{OTPError}</div>}

            <div className="mt-6 text-neutrals-200 w-full">
                <Button
                    text={OTPButtonText}
                    fullWidth
                    disabled={OTP.length !== 6}
                    size={'md'}
                    loading={OTPFormLoading}
                    onClick={() => handleSubmitOTP()}></Button>
            </div>

            {renderShowCounter()}

            {typeof OTPFooter !== 'string' ? OTPFooter : <p className="text-sm text-neutrals-900">{OTPFooter}</p>}
        </section>
    )
}
