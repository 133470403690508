import { ChangeEvent, MouseEventHandler, useState } from 'react'

interface TextareaProps {
    /**
     * Label for textarea
     */
    label?: string
    /**
     * Append component beside the label
     */
    labelAppend?: JSX.Element
    /**
     * Input placeholder
     */
    placeholder?: string
    /**
     * Wrapper classname
     */
    className?: string
    /**
     * Specify textarea size
     */
    size?: keyof typeof inputSizeMap
    /**
     * Value of textarea
     */
    value?: string
    /**
     * Disable textarea
     */
    disabled?: boolean
    /**
     * Define textarea width
     */
    width?: number
    /**
     * Define textarea height
     */
    height?: number
    /**
     * Define textarea id
     */
    id?: string
    /**
     * Optional define maxlength
     */
    maxLength?: number
    /**
     * Variant of the text field
     */
    variant?: 'success' | 'error'
    /**
     * Input captions, can put success/error message here
     */
    captions?: string
    /**
     * Optional character Count
     */
    counter?: boolean
    /**
     * Optional textarea event
     */
    onInput?: (value: string) => void
    /**
     * Optioanl click textarea event
     */
    onClick?: MouseEventHandler<HTMLDivElement>
    /**
     * Optional max character limit event
     */
    onReachedMaxCount?: () => void
    /**
     * Optional on focus event
     */
    onFocus?: () => void
    /**
     * Optional on blur event
     */
    onBlur?: () => void
}

export const Textarea = ({
    label,
    labelAppend,
    placeholder,
    className,
    size = 'md',
    value = '',
    disabled,
    width,
    height,
    id,
    maxLength = 250,
    variant,
    captions = '',
    counter,
    onInput,
    onClick,
    onReachedMaxCount,
    onFocus,
    onBlur
}: TextareaProps) => {
    const [isFocus, setFocus] = useState(false)
    const characaterCount = value.length

    const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (!onInput || disabled) {
            return
        }

        onInput(e.target.value)

        if (e.target.value.length >= maxLength) {
            handleReachedMaxCount()
        }
    }

    const handleReachedMaxCount = () => {
        if (!onReachedMaxCount) {
            return
        }

        onReachedMaxCount()
    }

    const handleFocus = (state: boolean) => {
        setFocus(state)

        if (value) {
            if (onFocus) onFocus()
            return
        }

        if (onBlur) onBlur()
    }

    return (
        <>
            <div className={className}>
                {!!label && (
                    <div className="mb-1 flex items-center">
                        <label className="text-sm text-neutrals-900 leading-normal font-medium block" htmlFor={id}>
                            {label}
                        </label>
                        {!!labelAppend && <div className="ml-1">{labelAppend}</div>}
                    </div>
                )}
                <div
                    className={`flex flex-col border rounded-lg w-full relative ${inputSizeMap[size]} ${
                        disabled
                            ? 'bg-neutrals-100 border-neutrals-400'
                            : !!variant
                            ? inputVariantMap[variant].input
                            : isFocus
                            ? 'border-primary-600 text-neutrals-900 bg-neutrals-light'
                            : `bg-neutrals-light text-neutrals-500 border-neutrals-400 ${
                                  value.length > 0 ? 'text-neutrals-800' : 'text-neutrals-500'
                              }`
                    }`}
                    onClick={onClick}
                    style={{
                        width: width || 'auto',
                        height: height || 200
                    }}>
                    <textarea
                        className="flex-grow resize-none h-full flex-shrink min-w-0 outline-none bg-opacity-0 bg-transparent placeholder:text-neutrals-400 disabled:text-neutrals-400"
                        style={{ fontSize: 'inherit' }}
                        disabled={!!disabled}
                        onFocus={() => handleFocus(true)}
                        onBlur={() => handleFocus(false)}
                        value={value}
                        onInput={handleInput}
                        placeholder={placeholder || ''}
                        id={id}
                        maxLength={maxLength}
                        autoComplete="off"
                    />
                    {counter && (
                        <span className="flex justify-end text-xs leading-normal">
                            {characaterCount} / {maxLength}
                        </span>
                    )}
                </div>
                {captions && (
                    <div
                        className={`${captions.length ? 'block mt-1' : 'hidden'}
             text-xs leading-normal ${!!variant ? inputVariantMap[variant].captions : 'text-neutrals-500'}`}>
                        {captions}
                    </div>
                )}
            </div>
        </>
    )
}

const inputSizeMap = {
    sm: 'text-sm leading-none px-4 py-3.5',
    md: 'text-base leading-none px-4 py-3.5',
    lg: 'text-base leading-normal px-4 py-3.5'
}

const inputVariantMap = {
    success: {
        input: 'text-neutrals-900 border-green-500',
        captions: 'text-green-500'
    },
    error: {
        input: 'text-neutrals-900 border-red-500',
        captions: 'text-red-500'
    }
}
