import React from 'react'

interface ProgressBarProps {
    /**
     * Append Percentage with progress value
     */
    appendPercentage: boolean
    /**
     * progress bar label
     */
    label: string
    /**
     * className
     */
    className: string
    /**
     * Hide Progress Value
     */
    hideProgressValue: boolean
    /**
     * Progress Value
     */
    progress: number
    /**
     * Size of Progress Bar
     */
    size: keyof typeof progressSizeMap
}

export const ProgressBar = ({
    appendPercentage = true,
    label,
    className,
    hideProgressValue = false,
    progress = 0,
    size = 'md'
}: ProgressBarProps) => {
    return (
        <div className={className}>
            <div className="flex justify-between mb-1">
                <span className={`${progressSizeMap[size].textSize} font-medium text-blue-500`}>{label}</span>
                {!hideProgressValue && (
                    <span className={`${progressSizeMap[size].textSize} font-medium text-blue-500`}>
                        {appendPercentage ? `${progress}%` : progress}
                    </span>
                )}
            </div>
            <div className={`w-full bg-gray-200 rounded-full ${progressSizeMap[size].height}`}>
                <div
                    className={`bg-blue-500 rounded-full ${progressSizeMap[size].height}`}
                    style={{ width: `${progress}%` }}></div>
            </div>
        </div>
    )
}

const progressSizeMap = {
    sm: {
        textSize: 'text-base',
        height: 'h-1.5'
    },
    md: {
        textSize: 'text-base',
        height: 'h-2.5'
    },
    lg: {
        textSize: 'text-lg',
        height: 'h-4'
    },
    xl: {
        textSize: 'text-lg',
        height: 'h-6'
    }
}
