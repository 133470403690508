import { MouseEventHandler, useRef, useState } from 'react'
import { useOnClickOutside } from '~/hooks'
import { Dropdown } from '../Dropdown'
import { Icon } from '../Icon'

interface SelectWrapperProps {
    /**
     * Label for input
     */
    label?: string
    /**
     * Append component beside the label
     */
    labelAppend?: JSX.Element
    /**
     * Variant of the text field
     */
    variant?: 'success' | 'error'
    /**
     * SVG icon
     */
    icon?: JSX.Element
    /**
     * Postition of icon
     */
    iconPosition?: 'left' | 'right'
    /**
     * Input placeholder
     */
    placeholder?: string
    /**
     * Wrapper classname
     */
    className?: string
    /**
     * Specify input size
     */
    size?: keyof typeof inputSizeMap
    /**
     * Value of input
     */
    value?: any
    /**
     * Append component beside input
     */
    children?: JSX.Element | JSX.Element[]
    /**
     * Disable input
     */
    disabled?: boolean
    /**
     * Define input width
     */
    width?: number
    /**
     * Define input id
     */
    id?: string
    /**
     * Input captions, can put success/error message here
     */
    captions?: string
    /**
     * Prepend element to dropdown
     */
    prepend?: JSX.Element
    /**
     * Append element to dropdown
     */
    append?: JSX.Element
    /**
     * Min width of dropdown
     */
    dropdownMinWidth?: number
    /**
     * Match dropdown width to select parent
     */
    fitToParentBox?: boolean
    /**
     * Customize options id dropdown
     */
    dropdownOptions?: JSX.Element
    /**
     * Optional click input event
     */
    onClick?: MouseEventHandler<HTMLDivElement>
}

export const SelectWrapper = ({
    label,
    labelAppend,
    variant,
    icon,
    iconPosition = 'left',
    placeholder,
    className,
    size = 'md',
    value,
    children,
    disabled = false,
    width,
    id,
    captions,
    prepend,
    append,
    dropdownMinWidth,
    fitToParentBox = false,
    dropdownOptions,
    onClick
}: SelectWrapperProps) => {
    const [isFocus, setFocus] = useState(false)
    const [defaultValue] = useState('')

    const wrapper = useRef(null)

    const handleClickOutside = () => {
        setFocus(false)
    }

    const handleClickInside = () => {
        if (disabled) {
            return
        }

        setFocus(true)
    }

    useOnClickOutside(wrapper, handleClickOutside)

    return (
        <>
            <div className={className}>
                {!!label && (
                    <div className="mb-1 flex items-center">
                        <label className="text-sm text-neutrals-900 leading-normal font-medium block" htmlFor={id}>
                            {label}
                        </label>
                        {!!labelAppend && <div className="ml-1">{labelAppend}</div>}
                    </div>
                )}
                <div className={fitToParentBox ? '' : 'relative'} ref={wrapper} onClick={handleClickInside}>
                    <div
                        className={`flex border rounded-lg w-full items-center relative ${inputSizeMap[size]} ${
                            disabled
                                ? 'bg-neutrals-100 border-neutrals-400 text-neutrals-400'
                                : !!variant
                                ? inputVariantMap[variant].input
                                : isFocus
                                ? 'border-primary-600 text-neutrals-900 bg-neutrals-light'
                                : `bg-neutrals-light text-neutrals-500 border-neutrals-400 ${
                                      value.length > 0 ? 'text-neutrals-800' : 'text-neutrals-500'
                                  }`
                        }`}
                        onClick={onClick}
                        role="button"
                        style={{
                            width: width || 'auto'
                        }}>
                        {iconPosition === 'left' ? (
                            <span className={!!icon ? 'mr-2.5 flex-shrink-0 flex items-center' : ''}>{icon}</span>
                        ) : (
                            children
                        )}

                        <input
                            className={`box-content flex-grow flex-shrink min-w-0 outline-none bg-opacity-0 bg-transparent truncate cursor-pointer ${
                                heightMap[size]
                            } ${value === defaultValue ? 'text-neutrals-400' : ''}`}
                            style={{ fontSize: 'inherit' }}
                            type="text"
                            id={id}
                            readOnly
                            placeholder={placeholder}
                            value={value}
                            autoComplete="off"
                        />
                        {iconPosition === 'right' ? (
                            <span className={!!icon ? 'ml-2.5 flex-shrink-0 flex items-center' : ''}>{icon}</span>
                        ) : (
                            children
                        )}
                        <Icon name="chevron-down-outline"></Icon>
                    </div>
                    <Dropdown
                        isFocus={isFocus}
                        dropdownMinWidth={dropdownMinWidth}
                        prepend={prepend}
                        append={append}
                        parentRef={wrapper}>
                        <div onClick={handleClickOutside}>{dropdownOptions}</div>
                    </Dropdown>
                </div>
                {captions && (
                    <div
                        className={`${captions.length ? 'block mt-1' : 'hidden'} text-xs leading-normal ${
                            !!variant ? inputVariantMap[variant].captions : 'text-neutrals-500'
                        }`}>
                        {captions}
                    </div>
                )}
            </div>
        </>
    )
}

const inputSizeMap = {
    sm: 'text-sm leading-none px-4 py-2.5',
    md: 'text-base leading-none px-4 py-2.5',
    lg: 'text-base leading-normal px-4 py-2.5'
}

const heightMap = {
    sm: 'h-3.5 py-0.5',
    md: 'h-4.5 py-0.5',
    lg: 'h-6.5 py-0.5'
}

const inputVariantMap = {
    success: {
        input: 'text-neutrals-900 border-green-500',
        captions: 'text-green-500'
    },
    error: {
        input: 'text-neutrals-900 border-red-500',
        captions: 'text-red-500'
    }
}
