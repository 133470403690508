interface ChipProps {
    /**
     * Text or label of chip
     */
    text: string
    /**
     * Chip disabled state
     */
    disabled?: boolean
    /**
     * Chip active state
     */
    active?: boolean
    /**
     * Icon for chip
     */
    icon?: JSX.Element
    /**
     * Icon position
     */
    iconPosition?: 'left' | 'right'
    /**
     * Passing element
     */
    children?: JSX.Element
    /**
     * Optional click event
     */
    onClick?: () => void
    /**
     * Option click event when disabled
     */
    onDisabledClick?: () => void
}

export const Chip = ({
    text,
    disabled = false,
    active = false,
    icon,
    iconPosition = 'left',
    children,
    onClick,
    onDisabledClick
}: ChipProps) => {
    const handleClick = () => {
        if (disabled) {
            if (onDisabledClick) onDisabledClick()
            return
        }

        if (!onClick) {
            return
        }

        onClick()
    }

    return (
        <>
            <button
                type="button"
                className={`inline-flex items-center justify-center relative text-center overflow-hidden flex-shrink-0 flex-grow-0 leading-normal rounded-full border px-4.5 py-2.5 text-sm ${
                    disabled
                        ? 'bg-neutrals-200 text-neutrals-900 hover:bg-neutrals-300 border-transparent'
                        : active
                        ? 'bg-primary-600 text-neutrals-light hover:bg-primary-800 border-transparent'
                        : 'bg-neutrals-light text-neutrals-900 hover:bg-neutrals-50 border-neutrals-400'
                }`}
                onClick={() => handleClick()}
                role="button"
                aria-label="button"
                data-testid="button_component">
                {!!children ? (
                    children
                ) : (
                    <>
                        {!!icon && iconPosition === 'left' && (
                            <>
                                {icon}
                                <span className="mr-1"></span>
                            </>
                        )}
                        <span className="inline-block font-bold">{text}</span>
                        {!!icon && iconPosition === 'right' && (
                            <>
                                <span className="ml-1"></span>
                                {icon}
                            </>
                        )}
                    </>
                )}
            </button>
        </>
    )
}
