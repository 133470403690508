import { ReactElement, useState } from 'react'
import { Countries } from '~/constants/countries'
import { Select, SelectOption } from './Select'

interface SelectCountryProps {
    /**
     * Select country value
     */
    value: string
    /**
     * Optional field width
     */
    width?: number
    /**
     * Min width of dropdown
     */
    dropdownMinWidth?: number
    /**
     * Max height of dropdown
     */
    dropdownMaxHeight?: number
    /**
     * dropdown placeholder
     */
    dropdownPlaceholder?: string
    /**
     * Match dropdown width to select parent
     */
    fitToParentBox?: boolean
    /**
     * disable select option
     */
    disabled?: boolean
    /**
     * on dropdown select
     */
    onSelect?: (value: any) => void
}

export const SelectCountry = ({
    value,
    width,
    dropdownMinWidth,
    dropdownMaxHeight,
    fitToParentBox,
    dropdownPlaceholder,
    disabled,
    onSelect
}: SelectCountryProps): ReactElement => {
    const [countryCode, setCountryCode] = useState(value || '+91')

    const getOptions = (): SelectOption[] => {
        return Countries.map((item) => ({
            text: item.code,
            value: item.code,
            longText: `(${item.code}) ${item.name}`,
            icon: item.svg
        }))
    }
    return (
        <Select
            id="countries"
            options={getOptions()}
            value={countryCode}
            placeholder={dropdownPlaceholder}
            width={width}
            dropdownMinWidth={dropdownMinWidth}
            dropdownMaxHeight={dropdownMaxHeight}
            fitToParentBox={fitToParentBox}
            disabled={disabled}
            onSelect={(val) => {
                setCountryCode(val)
                onSelect ? onSelect(val) : null
            }}></Select>
    )
}
