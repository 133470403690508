export const LoginImage = () => {
    return (
        <svg width="280" height="280" viewBox="0 0 280 280" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M280 218.811H0V218.951H280V218.811Z" fill="#EBEBEB" />
            <path d="M251.944 227.821H233.397V227.961H251.944V227.821Z" fill="#EBEBEB" />
            <path d="M185.483 229.344H180.617V229.484H185.483V229.344Z" fill="#EBEBEB" />
            <path d="M232.837 222.624H222.09V222.764H232.837V222.624Z" fill="#EBEBEB" />
            <path d="M53.564 223.565H29.3776V223.705H53.564V223.565Z" fill="#EBEBEB" />
            <path d="M62.0984 223.565H58.5536V223.705H62.0984V223.565Z" fill="#EBEBEB" />
            <path d="M126.084 225.928H73.6232V226.068H126.084V225.928Z" fill="#EBEBEB" />
            <path
                d="M132.72 193.835H24.5896C23.7425 193.833 22.9306 193.496 22.3322 192.896C21.7337 192.297 21.3976 191.484 21.3976 190.637V38.6362C21.405 37.7939 21.7443 36.9886 22.342 36.3952C22.9397 35.8017 23.7473 35.468 24.5896 35.4666H132.72C133.568 35.4666 134.381 35.8034 134.981 36.4031C135.581 37.0028 135.918 37.8161 135.918 38.6642V190.637C135.918 191.485 135.581 192.298 134.981 192.898C134.381 193.498 133.568 193.835 132.72 193.835ZM24.5896 35.5786C23.7796 35.58 23.0034 35.9028 22.4312 36.4761C21.859 37.0493 21.5376 37.8262 21.5376 38.6362V190.637C21.5376 191.447 21.859 192.224 22.4312 192.797C23.0034 193.37 23.7796 193.693 24.5896 193.695H132.72C133.53 193.693 134.307 193.37 134.88 192.797C135.453 192.224 135.776 191.447 135.778 190.637V38.6362C135.776 37.8257 135.453 37.0488 134.88 36.4757C134.307 35.9026 133.53 35.58 132.72 35.5786H24.5896Z"
                fill="#EBEBEB"
            />
            <path
                d="M253.854 193.835H145.718C144.87 193.833 144.058 193.496 143.458 192.896C142.859 192.297 142.522 191.485 142.52 190.637V38.6362C142.529 37.7934 142.869 36.9881 143.468 36.3948C144.067 35.8015 144.875 35.468 145.718 35.4666H253.854C254.695 35.4695 255.501 35.8038 256.098 36.3971C256.694 36.9904 257.033 37.7949 257.04 38.6362V190.637C257.04 191.483 256.705 192.295 256.107 192.894C255.51 193.493 254.7 193.832 253.854 193.835ZM145.718 35.5786C144.907 35.58 144.13 35.9026 143.557 36.4757C142.984 37.0488 142.661 37.8257 142.66 38.6362V190.637C142.661 191.447 142.984 192.224 143.557 192.797C144.13 193.37 144.907 193.693 145.718 193.695H253.854C254.664 193.693 255.441 193.37 256.014 192.797C256.587 192.224 256.91 191.447 256.911 190.637V38.6362C256.91 37.8257 256.587 37.0488 256.014 36.4757C255.441 35.9026 254.664 35.58 253.854 35.5786H145.718Z"
                fill="#EBEBEB"
            />
            <path d="M248.752 46.4705H156.66V128.186H248.752V46.4705Z" fill="#E6E6E6" />
            <path d="M246.322 48.7104H158.962V125.99H246.322V48.7104Z" fill="#F0F0F0" />
            <path d="M179.469 48.7104L158.962 125.822V89.5904L169.786 48.7104H179.469Z" fill="white" />
            <path d="M186.726 48.7104L166.046 125.99H162.747L183.428 48.7104H186.726Z" fill="white" />
            <path d="M246.322 97.4753V109.823L242.138 125.991H238.84L246.322 97.4753Z" fill="white" />
            <path d="M189.437 47.3218H187.964V127.715H189.437V47.3218Z" fill="#E6E6E6" />
            <path d="M217.952 47.3218H216.479V127.715H217.952V47.3218Z" fill="#E6E6E6" />
            <path d="M80.1528 173.255H29.3776V218.788H80.1528V173.255Z" fill="#F5F5F5" />
            <path d="M47.0512 173.255H29.3776V218.788H47.0512V173.255Z" fill="#EBEBEB" />
            <path d="M90.2552 128.656H39.48V174.19H90.2552V128.656Z" fill="#EBEBEB" />
            <path d="M57.1536 128.656H39.48V174.19H57.1536V128.656Z" fill="#E0E0E0" />
            <path d="M80.1528 83.2571H29.3776V128.791H80.1528V83.2571Z" fill="#F5F5F5" />
            <path d="M47.0512 83.2571H29.3776V128.791H47.0512V83.2571Z" fill="#EBEBEB" />
            <path d="M135.408 173.294H84.6328V218.827H135.408V173.294Z" fill="#EBEBEB" />
            <path d="M102.306 173.294H84.6328V218.827H102.306V173.294Z" fill="#E0E0E0" />
            <path d="M141.03 145.714H90.2552V174.234H141.03V145.714Z" fill="#F5F5F5" />
            <path d="M107.929 145.714H90.2552V174.234H107.929V145.714Z" fill="#E6E6E6" />
            <path d="M119.118 100.656H86.3127V146.19H119.118V100.656Z" fill="#EBEBEB" />
            <path d="M97.7311 100.656H86.3127V146.19H97.7311V100.656Z" fill="#E0E0E0" />
            <path d="M194.046 218.872H244.821V173.338H194.046V218.872Z" fill="#F5F5F5" />
            <path d="M227.147 218.872H244.821V173.338H227.147V218.872Z" fill="#E6E6E6" />
            <path d="M183.938 174.274H234.713V137.207H183.938V174.274Z" fill="#EBEBEB" />
            <path d="M217.045 174.274H234.718V137.207H217.045V174.274Z" fill="#E0E0E0" />
            <path d="M195.854 137.207H244.821V119.063H195.854V137.207Z" fill="#F5F5F5" />
            <path d="M227.786 137.207H244.826V119.063H227.786V137.207Z" fill="#E6E6E6" />
            <path
                d="M140 244.1C199.966 244.1 248.578 241.262 248.578 237.761C248.578 234.26 199.966 231.422 140 231.422C80.0338 231.422 31.4216 234.26 31.4216 237.761C31.4216 241.262 80.0338 244.1 140 244.1Z"
                fill="#F5F5F5"
            />
            <path
                d="M200.402 66.2385H184.817C183.416 66.2385 182.28 67.3743 182.28 68.7753V82.9097C182.28 84.3108 183.416 85.4465 184.817 85.4465H200.402C201.803 85.4465 202.938 84.3108 202.938 82.9097V68.7753C202.938 67.3743 201.803 66.2385 200.402 66.2385Z"
                fill="#0052CC"
            />
            <path
                d="M190.59 83.0049C190.59 83.0049 191.895 89.3161 200.788 92.8161C200.788 92.8161 195.35 89.3385 195.686 83.0049H190.59Z"
                fill="#0052CC"
            />
            <path
                d="M188.138 75.2994L191.089 79.3986L197.081 72.2922"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M219.817 88.3417C222.555 90.3073 225.03 93.6673 223.177 103.075C221.323 112.483 220.853 114.723 220.853 114.723L202.933 112.707C202.933 112.707 202.093 104.38 203.493 96.5457C205.386 86.4265 211.798 82.5793 219.817 88.3417Z"
                fill="#263238"
            />
            <path
                d="M201.88 140.327C201.88 140.327 195.81 172.667 194.191 185.955C192.074 203.282 183.422 228.627 183.422 228.627L186.883 229.523C186.883 229.523 199.08 207.98 201.443 188.604C211.131 168.797 211.915 144.403 211.915 144.403L201.88 140.327Z"
                fill="#EEC1BB"
            />
            <path
                d="M176.96 236.467C177.519 236.21 178.121 236.058 178.735 236.019C178.735 236.019 180.863 234.765 182.969 229.904H185.343C185.743 229.91 186.132 230.038 186.458 230.271C186.783 230.504 187.029 230.832 187.163 231.209C187.202 231.315 187.225 231.426 187.23 231.539C187.204 232.103 187.142 232.664 187.046 233.219C186.698 235.112 186.236 236.982 185.662 238.819L185.259 238.713L185.987 235.179C186.234 233.975 184.968 235.134 184.386 236.35C183.803 237.565 182.924 238.746 180.32 238.186C177.251 237.458 176.025 237.016 176.96 236.467Z"
                fill="#263238"
            />
            <path
                d="M187.253 231.254C187.701 230.106 188.272 228.051 188.272 228.051L183.193 227.345C183.193 227.345 183.193 229.843 182.252 231.478C184.906 231.467 187.253 231.254 187.253 231.254Z"
                fill="#263238"
            />
            <path
                d="M208.18 139.811C208.18 139.811 208.936 172.711 210.095 186.039C211.613 203.433 208.387 230.022 208.387 230.022L211.96 230.178C211.96 230.178 219.436 206.58 217.756 187.126C223.138 165.745 218.876 141.721 218.876 141.721L208.18 139.811Z"
                fill="#EEC1BB"
            />
            <path
                d="M202.832 237.554C203.361 237.236 203.944 237.017 204.551 236.91C204.551 236.91 206.528 235.437 208.102 230.375L210.454 230.106C210.855 230.068 211.258 230.155 211.608 230.356C211.958 230.556 212.238 230.86 212.408 231.226C212.458 231.326 212.492 231.434 212.509 231.545C212.545 232.117 212.545 232.692 212.509 233.264C212.365 235.175 212.105 237.075 211.73 238.954L211.322 238.898L211.663 235.303C211.775 234.082 210.65 235.37 210.196 236.641C209.742 237.912 208.981 239.217 206.315 238.881C203.213 238.506 201.942 238.187 202.832 237.554Z"
                fill="#263238"
            />
            <path
                d="M212.481 231.254C212.8 230.067 213.147 227.961 213.147 227.961L208.023 227.81C208.023 227.81 208.292 230.291 207.536 232.021C210.168 231.713 212.481 231.254 212.481 231.254Z"
                fill="#263238"
            />
            <path
                d="M200.586 134.928L193.928 180.971C193.928 180.971 215.611 184.331 224.291 182.366C224.291 182.366 226.425 155.783 217.364 136.446L200.586 134.928Z"
                fill="#263238"
            />
            <path
                d="M180.544 118.649L174.748 112.926C174.748 112.926 174.552 111.391 173.986 110.966C173.421 110.54 169.456 109.095 168.946 109.415C168.437 109.734 167.782 112.948 168.465 113.536C169.148 114.124 172.76 114.875 173.13 114.813L179.418 122.267L180.544 118.649Z"
                fill="#EEC1BB"
            />
            <path
                d="M201.286 108.216C198.789 109.543 189.918 121.942 188.614 122.552C187.309 123.162 177.324 115.07 177.324 115.07L175.644 117.943C175.644 117.943 183.831 129.546 188.462 128.986C193.094 128.426 201.958 118.626 201.958 118.626L201.286 108.216Z"
                fill="#0052CC"
            />
            <path
                opacity="0.1"
                d="M201.286 108.216C198.789 109.543 189.918 121.942 188.614 122.552C187.309 123.162 177.324 115.07 177.324 115.07L175.644 117.943C175.644 117.943 183.831 129.546 188.462 128.986C193.094 128.426 201.958 118.626 201.958 118.626L201.286 108.216Z"
                fill="white"
            />
            <path
                d="M177.873 115.379L177.178 114.723L175.358 117.803L176.014 119.063L177.873 115.379Z"
                fill="#0052CC"
            />
            <path
                opacity="0.2"
                d="M198.397 122.216C200.469 120.239 201.958 118.599 201.958 118.599L201.802 116.135L201.04 113.743C199.966 114.849 199.167 116.193 198.707 117.664C198.248 119.136 198.142 120.696 198.397 122.216Z"
                fill="black"
            />
            <path
                d="M200.446 134.458C199.746 122.138 200.06 115.35 200.379 111.962C200.732 108.35 201.286 108.216 201.286 108.216C201.286 108.216 205.206 107.835 208.606 107.863C211.235 107.952 213.855 108.229 216.446 108.692C218.911 109.101 221.344 109.687 223.726 110.445C223.328 111.565 222.97 112.685 222.634 113.67C222.191 115.009 221.794 116.246 221.43 117.406C220.758 119.567 220.231 121.438 219.817 123.112C218.746 127.328 218.121 131.645 217.952 135.992C217.952 135.992 222.258 147.248 223.098 155.508L208.818 157.048L206.858 148.788L204.898 157.328L196.918 154.528L200.446 134.458Z"
                fill="#0052CC"
            />
            <path
                opacity="0.1"
                d="M200.446 134.458C199.746 122.138 200.06 115.35 200.379 111.962C200.732 108.35 201.286 108.216 201.286 108.216C201.286 108.216 205.206 107.835 208.606 107.863C211.235 107.952 213.855 108.229 216.446 108.692C218.911 109.101 221.344 109.687 223.726 110.445C223.328 111.565 222.97 112.685 222.634 113.67C222.191 115.009 221.794 116.246 221.43 117.406C220.758 119.567 220.231 121.438 219.817 123.112C218.746 127.328 218.121 131.645 217.952 135.992C217.952 135.992 222.258 147.248 223.098 155.508L208.818 157.048L206.858 148.788L204.898 157.328L196.918 154.528L200.446 134.458Z"
                fill="white"
            />
            <path
                opacity="0.2"
                d="M219.122 126.131C219.313 125.19 219.537 124.187 219.811 123.112C219.985 122.429 220.175 121.712 220.371 120.951C219.811 119.041 219.251 120.536 219.251 120.536C218.904 122.381 218.86 124.271 219.122 126.131Z"
                fill="black"
            />
            <path
                d="M208.801 108.614C208.974 108.527 209.152 108.45 209.333 108.384C209.737 108.065 210.229 107.298 210.207 105.433C210.189 104.694 210.12 103.956 210 103.227L215.191 102.258C215.191 102.258 213.421 105.752 214.894 108.351C215.404 108.575 215.706 108.911 215.65 109.319C215.264 112.315 208.813 115.216 208.813 115.216C208.813 115.216 205.671 110.333 208.801 108.614Z"
                fill="#EEC1BB"
            />
            <path
                opacity="0.2"
                d="M210 103.227C210.117 103.951 210.184 104.683 210.202 105.416C213.707 105.416 215.191 102.258 215.191 102.258L210 103.227Z"
                fill="black"
            />
            <path
                d="M212.873 88.7281C208.359 88.4817 206.774 90.8169 206.192 95.4481C205.458 101.239 206.522 105.439 212.234 104.509C219.99 103.227 220.371 89.1369 212.873 88.7281Z"
                fill="#EEC1BB"
            />
            <path
                d="M209.121 95.3865C208.723 96.0405 208.247 96.644 207.704 97.1841C207.828 97.3757 208.002 97.5294 208.207 97.6282C208.413 97.727 208.642 97.7671 208.869 97.7441L209.121 95.3865Z"
                fill="#D4827D"
            />
            <path
                d="M211.904 95.1401C211.904 95.5097 212.05 95.8289 212.296 95.8457C212.542 95.8625 212.766 95.5825 212.8 95.2129C212.834 94.8433 212.649 94.5241 212.402 94.5073C212.156 94.4905 211.932 94.7705 211.904 95.1401Z"
                fill="#263238"
            />
            <path
                d="M212.503 94.5297L211.725 94.1545C211.725 94.1545 212.072 94.8601 212.503 94.5297Z"
                fill="#263238"
            />
            <path
                d="M207.306 94.7033C207.278 95.0729 207.452 95.3865 207.698 95.4089C207.945 95.4313 208.174 95.1457 208.202 94.7705C208.23 94.3953 208.051 94.0873 207.805 94.0649C207.558 94.0425 207.334 94.3337 207.306 94.7033Z"
                fill="#263238"
            />
            <path d="M207.906 94.093L207.127 93.7122C207.127 93.7122 207.474 94.4234 207.906 94.093Z" fill="#263238" />
            <path
                d="M212.122 92.0265C212.481 92.0024 212.84 92.061 213.172 92.1979C213.504 92.3349 213.8 92.5464 214.038 92.8161"
                stroke="#263238"
                strokeWidth="0.686238"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M208.88 91.6571C208.576 91.5612 208.254 91.5356 207.939 91.5822C207.624 91.6287 207.323 91.7462 207.06 91.9259"
                stroke="#263238"
                strokeWidth="0.686238"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M217.392 96.6074C217.392 96.6074 215.482 92.7826 215.958 89.9378C215.958 89.9378 207.217 85.7938 206.276 94.8434C206.276 94.8434 205.845 86.7346 213.612 87.0034C221.379 87.2722 220.724 98.601 216.535 101.765C216.535 101.743 218.568 98.9986 217.392 96.6074Z"
                fill="#263238"
            />
            <path
                d="M216.602 97.5201C216.677 97.0006 216.955 96.532 217.375 96.217C217.795 95.9021 218.323 95.7666 218.842 95.8401C220.231 96.0697 220.741 98.9817 217.224 99.4801C216.72 99.5361 216.457 99.0321 216.602 97.5201Z"
                fill="#EEC1BB"
            />
            <path
                d="M212.391 98.7856C212.049 99.1227 211.644 99.3888 211.199 99.5686C210.754 99.7483 210.278 99.8381 209.798 99.8328"
                stroke="#263238"
                strokeWidth="0.686238"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M215.958 89.9378C215.958 89.9378 206.203 87.401 205.386 96.5458"
                stroke="#263238"
                strokeWidth="0.686238"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M207.256 110.837C207.256 110.837 206.511 118.251 207.732 124.999C207.732 124.999 210.61 114.572 214.654 111.027L210.006 111.694L207.256 110.837Z"
                fill="#0052CC"
            />
            <path
                opacity="0.5"
                d="M207.256 110.837C207.256 110.837 206.511 118.251 207.732 124.999C207.732 124.999 210.61 114.572 214.654 111.027L210.006 111.694L207.256 110.837Z"
                fill="white"
            />
            <path
                d="M214.721 107.538L210 111.027V107.32C210 107.32 206.186 108.703 206.455 112.92L209.586 111.481V112.92L210.319 111.531L212.794 114.359C212.794 114.359 215.824 110.826 216.154 109.762C216.485 108.698 214.721 107.538 214.721 107.538Z"
                fill="#0052CC"
            />
            <path
                opacity="0.7"
                d="M214.721 107.538L210 111.027V107.32C210 107.32 206.186 108.703 206.455 112.92L209.586 111.481V112.92L210.319 111.531L212.794 114.359C212.794 114.359 215.824 110.826 216.154 109.762C216.485 108.698 214.721 107.538 214.721 107.538Z"
                fill="white"
            />
            <path
                d="M219.649 140.254L215.314 119.573C215.269 119.356 215.151 119.162 214.98 119.022C214.809 118.883 214.595 118.806 214.374 118.806H201.04C200.942 118.806 200.845 118.827 200.756 118.869C200.667 118.911 200.588 118.971 200.525 119.046C200.462 119.122 200.417 119.21 200.391 119.305C200.366 119.399 200.362 119.499 200.379 119.595L203.991 139.845C204.029 140.063 204.141 140.262 204.308 140.407C204.476 140.552 204.688 140.634 204.91 140.64L219.005 141.06C219.103 141.058 219.2 141.034 219.288 140.991C219.376 140.947 219.454 140.884 219.515 140.808C219.577 140.731 219.621 140.641 219.644 140.545C219.667 140.45 219.668 140.35 219.649 140.254Z"
                fill="#263238"
            />
            <path
                opacity="0.1"
                d="M219.649 140.254L215.314 119.573C215.269 119.356 215.151 119.162 214.98 119.022C214.809 118.883 214.595 118.806 214.374 118.806H201.04C200.942 118.806 200.845 118.827 200.756 118.869C200.667 118.911 200.588 118.971 200.525 119.046C200.462 119.122 200.417 119.21 200.391 119.305C200.366 119.399 200.362 119.499 200.379 119.595L203.991 139.845C204.029 140.063 204.141 140.262 204.308 140.407C204.476 140.552 204.688 140.634 204.91 140.64L219.005 141.06C219.103 141.058 219.2 141.034 219.288 140.991C219.376 140.947 219.454 140.884 219.515 140.808C219.577 140.731 219.621 140.641 219.644 140.545C219.667 140.45 219.668 140.35 219.649 140.254Z"
                fill="white"
            />
            <path
                d="M225.411 133.556L218.517 129.211C218.517 129.211 217.991 127.76 217.353 127.463C216.714 127.167 212.525 126.612 212.117 127.032C211.708 127.452 211.741 130.728 212.537 131.159C213.332 131.591 217.017 131.54 217.364 131.4L225.086 137.314L225.411 133.556Z"
                fill="#EEC1BB"
            />
            <path
                d="M223.726 110.445C223.726 110.445 219.246 116.986 219.878 122.586L225.854 132.475L221.838 130.426L220.018 133.853C220.018 133.853 224.638 140.971 230.518 139.291C236.398 137.611 223.726 110.445 223.726 110.445Z"
                fill="#0052CC"
            />
            <path
                opacity="0.1"
                d="M223.726 110.445C223.726 110.445 219.246 116.986 219.878 122.586L225.854 132.475L221.838 130.426L220.018 133.853C220.018 133.853 224.638 140.971 230.518 139.291C236.398 137.611 223.726 110.445 223.726 110.445Z"
                fill="white"
            />
            <path
                d="M222.258 130.235L219.974 134.486L219.458 133.271L221.278 129.815L222.258 130.235Z"
                fill="#0052CC"
            />
            <path
                d="M213.92 118.806C213.394 118.806 213.394 132.582 213.394 132.582L201.354 132.235C201.354 132.235 199.998 120.587 201.354 118.795L213.92 118.806Z"
                fill="white"
            />
            <path
                opacity="0.1"
                d="M213.92 118.806C213.394 118.806 213.394 132.582 213.394 132.582L201.354 132.235C201.354 132.235 199.998 120.587 201.354 118.795L213.92 118.806Z"
                fill="black"
            />
            <path
                d="M164.192 107.891L175.577 112.679C175.638 112.706 175.694 112.745 175.74 112.793C175.787 112.841 175.823 112.898 175.848 112.961C175.872 113.023 175.884 113.09 175.883 113.157C175.881 113.224 175.867 113.29 175.84 113.351C175.787 113.472 175.688 113.567 175.566 113.616C175.443 113.664 175.306 113.662 175.185 113.609L163.727 108.754L163.621 108.698L162.288 107.707L164.041 107.858C164.093 107.861 164.144 107.872 164.192 107.891Z"
                fill="#263238"
            />
            <path
                d="M126.42 131.49L115.36 132.475L110.925 129.972C110.925 129.972 96.7009 122.048 93.0049 119.545C91.8849 118.778 90.8377 117.109 89.9361 115.149C89.1796 113.468 88.5287 111.742 87.9873 109.98C87.7353 109.191 87.5113 108.435 87.3209 107.74C86.8953 106.245 86.6321 105.125 86.5593 104.823C86.5626 104.798 86.5626 104.774 86.5593 104.75H86.5873L90.0929 103.63L93.0217 102.7C93.0217 102.7 93.1561 103.395 93.3857 104.453L93.5873 105.388C93.6489 105.657 93.7105 105.948 93.7777 106.239C94.5169 109.499 95.6537 113.945 96.4769 114.673C97.8041 115.843 114.492 127.217 115.086 127.514C115.679 127.811 120.994 127.514 120.994 127.514L126.42 131.49Z"
                fill="#E4897B"
            />
            <path
                d="M86.4696 89.0474C91.5656 88.969 94.808 104.761 94.808 104.761L88.088 108.121C86.1791 104.816 85.1837 101.063 85.204 97.2458C85.3608 91.1866 86.4696 89.0474 86.4696 89.0474Z"
                fill="#0052CC"
            />
            <path
                opacity="0.5"
                d="M86.4696 89.0474C91.5656 88.969 94.808 104.761 94.808 104.761L88.088 108.121C86.1791 104.816 85.1837 101.063 85.204 97.2458C85.3608 91.1866 86.4696 89.0474 86.4696 89.0474Z"
                fill="white"
            />
            <path
                d="M95.5528 105.069L93.7384 106.245L91.3696 107.779L87.9648 109.98L87.3544 110.378L86.6152 108.132L87.2984 107.763L87.7464 107.516L90.86 105.819L93.3464 104.459L94.5896 103.781L94.808 103.663L95.5528 105.069Z"
                fill="#0052CC"
            />
            <path
                opacity="0.2"
                d="M91.6721 110.378C91.6825 112.129 91.0578 113.824 89.9137 115.149C89.1571 113.468 88.5062 111.742 87.9649 109.98L87.3545 110.378L86.6153 108.138L87.2985 107.768C86.8729 106.273 86.6097 105.153 86.5369 104.851V104.761L87.3433 98.6011C88.4014 100.191 89.3052 101.879 90.0425 103.641C90.3337 104.33 90.6025 105.063 90.8489 105.825C90.9347 106.086 91.0131 106.355 91.0841 106.631C91.1905 107.012 91.2801 107.399 91.3585 107.785C91.5405 108.638 91.6454 109.506 91.6721 110.378Z"
                fill="black"
            />
            <path d="M85.708 233.908L90.3952 234.407L91.2744 224.299L86.5872 223.8L85.708 233.908Z" fill="#E4897B" />
            <path d="M62.328 233.567H66.864L67.2392 223.05H62.6976L62.328 233.567Z" fill="#E4897B" />
            <path
                d="M67.4969 233.04H61.9809C61.8907 233.042 61.8036 233.074 61.7335 233.13C61.6635 233.187 61.6145 233.266 61.5945 233.354L60.6985 237.397C60.6796 237.483 60.678 237.572 60.6937 237.659C60.7094 237.746 60.7422 237.828 60.7901 237.902C60.838 237.976 60.9001 238.04 60.9728 238.09C61.0455 238.139 61.1274 238.174 61.2137 238.192C61.2618 238.2 61.3111 238.2 61.3593 238.192C63.1457 238.192 64.4449 238.058 66.6849 238.058C68.0681 238.058 72.2345 238.198 74.1385 238.198C76.0425 238.198 76.2945 236.316 75.5105 236.143C72.0105 235.381 69.3505 234.323 68.2305 233.343C68.031 233.156 67.7701 233.048 67.4969 233.04Z"
                fill="#263238"
            />
            <path
                d="M67.8104 233.673C67.7884 233.668 67.7686 233.655 67.7536 233.639C67.7386 233.622 67.7292 233.6 67.7264 233.578C67.7238 233.558 67.7272 233.538 67.7362 233.52C67.7452 233.502 67.7593 233.487 67.7768 233.477C67.9392 233.371 69.4064 232.469 69.9104 232.732C69.9677 232.757 70.016 232.799 70.049 232.852C70.082 232.905 70.0981 232.967 70.0952 233.029C70.1064 233.134 70.0919 233.239 70.0528 233.337C70.0138 233.435 69.9514 233.521 69.8712 233.589C69.6158 233.766 69.3072 233.849 68.9976 233.824C68.5974 233.819 68.1992 233.768 67.8104 233.673ZM68.1184 233.522C68.6488 233.702 69.228 233.672 69.7368 233.438C69.7899 233.39 69.8308 233.329 69.8561 233.262C69.8815 233.195 69.8905 233.123 69.8824 233.051C69.8824 232.967 69.8432 232.928 69.8096 232.928C69.7689 232.905 69.7219 232.895 69.6752 232.9C69.1211 233.005 68.5924 233.216 68.1184 233.522Z"
                fill="#0052CC"
            />
            <path
                d="M67.7601 233.707C67.7439 233.695 67.7308 233.68 67.722 233.663C67.7132 233.645 67.709 233.625 67.7097 233.606C67.7097 233.544 67.8273 232.239 68.3537 231.814C68.4144 231.759 68.4865 231.718 68.5649 231.693C68.6433 231.669 68.726 231.662 68.8073 231.674C68.8885 231.673 68.9675 231.701 69.0307 231.752C69.094 231.803 69.1377 231.874 69.1545 231.954C69.2441 232.435 68.3369 233.432 67.8721 233.69H67.8217C67.8032 233.701 67.7818 233.707 67.7601 233.707ZM68.4881 231.937C68.1624 232.344 67.9672 232.84 67.9281 233.359C68.3985 233.007 68.9753 232.239 68.9193 231.954C68.9193 231.926 68.9193 231.847 68.7625 231.847H68.7121C68.6369 231.853 68.565 231.88 68.5049 231.926L68.4881 231.937Z"
                fill="#0052CC"
            />
            <path
                d="M90.888 232.9H85.372C85.2823 232.9 85.1953 232.931 85.1252 232.987C85.055 233.043 85.0058 233.121 84.9856 233.208L84.0896 237.251C84.053 237.425 84.0867 237.606 84.1832 237.755C84.2797 237.904 84.4313 238.009 84.6048 238.047H84.756C86.5368 238.013 87.836 237.907 90.0816 237.907C91.4592 237.907 95.6256 238.052 97.5296 238.052C99.4336 238.052 99.6856 236.165 98.9072 235.991C95.4072 235.23 92.7472 234.177 91.6272 233.191C91.4247 233.007 91.1617 232.904 90.888 232.9Z"
                fill="#263238"
            />
            <path
                d="M91.2017 233.527C91.1797 233.522 91.1599 233.51 91.1449 233.493C91.1299 233.476 91.1204 233.455 91.1177 233.432C91.1147 233.411 91.1186 233.39 91.1287 233.372C91.1388 233.354 91.1546 233.34 91.1737 233.331C91.3361 233.225 92.8033 232.323 93.3017 232.587C93.358 232.613 93.4055 232.655 93.4383 232.707C93.4711 232.76 93.4879 232.821 93.4865 232.883C93.4982 232.988 93.4839 233.094 93.4448 233.191C93.4057 233.289 93.3431 233.376 93.2625 233.443C93.0125 233.625 92.7089 233.718 92.4001 233.707C91.9951 233.693 91.593 233.632 91.2017 233.527ZM91.5097 233.376C92.04 233.553 92.6173 233.525 93.1281 233.298C93.1816 233.25 93.2227 233.19 93.248 233.122C93.2734 233.055 93.2822 232.983 93.2737 232.911C93.2737 232.822 93.2345 232.783 93.2065 232.783C93.1637 232.759 93.1149 232.749 93.0665 232.755C92.5131 232.862 91.9849 233.073 91.5097 233.376Z"
                fill="#0052CC"
            />
            <path
                d="M91.1737 233.55C91.1573 233.54 91.1436 233.526 91.1338 233.509C91.124 233.493 91.1185 233.474 91.1177 233.455C91.1177 233.387 91.2353 232.083 91.7617 231.657C91.8224 231.602 91.8945 231.561 91.9729 231.537C92.0513 231.512 92.134 231.506 92.2153 231.517C92.2973 231.517 92.3769 231.545 92.4403 231.597C92.5036 231.65 92.5468 231.722 92.5625 231.803C92.6577 232.279 91.7505 233.281 91.2801 233.533H91.2297C91.2129 233.544 91.1935 233.55 91.1737 233.55ZM91.9017 231.78C91.5735 232.186 91.378 232.682 91.3417 233.203C91.8065 232.85 92.3833 232.083 92.3273 231.803C92.3273 231.769 92.3273 231.691 92.1705 231.691H92.1201C92.0395 231.696 91.9629 231.728 91.9017 231.78Z"
                fill="#0052CC"
            />
            <path
                opacity="0.2"
                d="M86.5872 223.8L91.2745 224.299L90.8152 229.512L86.1281 229.014L86.5872 223.8Z"
                fill="black"
            />
            <path opacity="0.2" d="M62.6976 223.055H67.2392L67.0432 228.482H62.5016L62.6976 223.055Z" fill="black" />
            <path
                d="M64.5681 130.79H88.6481C90.9385 143.306 98.1121 187.585 92.9657 227.037L84.8065 226.023C84.8065 226.023 85.5625 173.647 78.3609 154.287C77.5321 164.647 76.5465 175.674 75.6953 181.543C73.6177 195.979 69.1321 227.547 69.1321 227.547H60.8273C60.8273 227.547 62.5073 197.34 63.3081 183.144C64.1649 167.733 64.5401 133.954 64.5681 130.79Z"
                fill="#0052CC"
            />
            <path
                opacity="0.2"
                d="M78.4 154.287C79.5871 157.682 80.4881 161.17 81.0936 164.715C81.7656 155.491 79.072 145.714 79.072 145.714L78.4 154.287Z"
                fill="black"
            />
            <path d="M93.9232 228.935L83.8264 227.435L84.1344 224.64L94.6344 226.018L93.9232 228.935Z" fill="#0052CC" />
            <path
                opacity="0.6"
                d="M93.9232 228.935L83.8264 227.435L84.1344 224.64L94.6344 226.018L93.9232 228.935Z"
                fill="white"
            />
            <path d="M70.1289 227.608H60.3345V224.679L70.4985 224.489L70.1289 227.608Z" fill="#0052CC" />
            <path opacity="0.6" d="M70.1289 227.608H60.3345V224.679L70.4985 224.489L70.1289 227.608Z" fill="white" />
            <path d="M64.5233 134.979L64.5009 131.49L89.0625 129.972L89.2361 133.355L64.5233 134.979Z" fill="#263238" />
            <path d="M70.7393 130.605H69.6305V135.718H70.7393V130.605Z" fill="#0052CC" />
            <path
                d="M87.7129 128.907L86.6071 128.988L86.9788 134.087L88.0847 134.006L87.7129 128.907Z"
                fill="#0052CC"
            />
            <path
                d="M89.0401 129.972L64.5009 131.787C64.5009 129.636 64.4449 127.239 64.2825 124.708C63.9801 120.032 63.4033 114.914 62.7593 110.115C61.3929 99.9169 59.7185 91.1641 59.7185 91.1641C71.1425 88.2801 86.4697 89.0305 86.4697 89.0305C90.4513 103.949 89.0401 129.972 89.0401 129.972Z"
                fill="#0052CC"
            />
            <path
                opacity="0.6"
                d="M89.0401 129.972L64.5009 131.787C64.5009 129.636 64.4449 127.239 64.2825 124.708C63.9801 120.032 63.4033 114.914 62.7593 110.115C61.3929 99.9169 59.7185 91.1641 59.7185 91.1641C71.1425 88.2801 86.4697 89.0305 86.4697 89.0305C90.4513 103.949 89.0401 129.972 89.0401 129.972Z"
                fill="white"
            />
            <path
                d="M68.4376 89.6355C70.6776 91.6459 76.0704 90.9067 76.0704 90.9067C76.0704 90.9067 79.4304 89.9771 78.7416 88.9187C76.748 88.5939 75.7848 87.6643 75.3816 86.4715C75.1603 85.7353 75.0917 84.9616 75.18 84.1979C75.2108 83.7712 75.2669 83.3467 75.348 82.9267L68.628 77.9651C69.5296 81.2411 70.6048 87.2611 68.4376 89.6355Z"
                fill="#E4897B"
            />
            <path
                opacity="0.2"
                d="M70.2017 81.1179C70.4649 85.1387 73.7689 86.2475 75.3873 86.4715C75.1659 85.7353 75.0973 84.9616 75.1857 84.1979L70.2017 81.1179Z"
                fill="black"
            />
            <path
                d="M81.76 68.4113C83.3896 69.9569 82.712 74.6665 79.3912 77.0745C76.0704 79.4825 76.0704 67.8345 76.0704 67.8345C76.0704 67.8345 80.724 67.4257 81.76 68.4113Z"
                fill="#263238"
            />
            <path
                d="M69.748 82.0475C70.4443 83.0783 71.4598 83.8519 72.6384 84.2495C73.8171 84.6471 75.0936 84.6467 76.272 84.2483C77.8044 83.7629 79.0942 82.7115 79.8784 81.3083C79.9892 81.1138 80.0902 80.9138 80.1808 80.7091C81.816 76.9067 82.32 70.1867 78.2936 67.6891C76.9112 66.8227 75.2415 66.5402 73.6509 66.9034C72.0604 67.2667 70.6789 68.2461 69.8096 69.6267C69.2954 70.4462 68.9791 71.3742 68.8856 72.3371C68.3928 76.7947 67.8888 79.4043 69.748 82.0475Z"
                fill="#E4897B"
            />
            <path
                d="M75.4041 74.7171C75.3201 75.0699 75.4825 75.4003 75.7233 75.4395C75.9641 75.4787 76.1993 75.2155 76.2833 74.8795C76.3673 74.5435 76.1993 74.1963 75.9697 74.1571C75.7401 74.1179 75.4713 74.3531 75.4041 74.7171Z"
                fill="#263238"
            />
            <path
                d="M79.4865 75.4114C79.4025 75.7642 79.5705 76.1002 79.8001 76.1394C80.0297 76.1786 80.2817 75.9154 80.3601 75.5794C80.4385 75.2434 80.276 74.8906 80.0408 74.8514C79.8056 74.8122 79.5481 75.0698 79.4865 75.4114Z"
                fill="#263238"
            />
            <path
                d="M78.1593 75.3667C78.4019 76.5366 78.8052 77.6673 79.3577 78.7267C79.0793 78.8625 78.7745 78.9354 78.4648 78.9403C78.1551 78.9451 77.8481 78.8818 77.5657 78.7547L78.1593 75.3667Z"
                fill="#DE5753"
            />
            <path
                d="M74.3625 73.177C74.4007 73.1881 74.441 73.1899 74.48 73.1821C74.519 73.1743 74.5555 73.1572 74.5865 73.1322C74.7777 72.9414 75.0113 72.7984 75.2681 72.7147C75.525 72.631 75.798 72.609 76.0649 72.6506C76.0921 72.6572 76.1204 72.6585 76.1481 72.6542C76.1757 72.6499 76.2023 72.6402 76.2263 72.6257C76.2502 72.6111 76.271 72.5919 76.2875 72.5693C76.304 72.5467 76.3159 72.521 76.3225 72.4938C76.3345 72.4389 76.3249 72.3815 76.2957 72.3336C76.2665 72.2856 76.2199 72.2507 76.1657 72.2362C75.831 72.1744 75.4864 72.1929 75.1603 72.2903C74.8341 72.3876 74.5358 72.561 74.2897 72.7962C74.2507 72.8368 74.2289 72.891 74.2289 72.9474C74.2289 73.0037 74.2507 73.0579 74.2897 73.0986C74.3086 73.1292 74.3333 73.1559 74.3625 73.177Z"
                fill="#263238"
            />
            <path
                d="M76.6585 80.5467C76.1287 80.5382 75.6088 80.4012 75.1436 80.1476C74.6784 79.894 74.2816 79.5314 73.9873 79.0907C73.9792 79.078 73.9739 79.0637 73.9715 79.0488C73.9691 79.0339 73.9698 79.0186 73.9734 79.004C73.9771 78.9893 73.9837 78.9756 73.9928 78.9635C74.0019 78.9515 74.0134 78.9414 74.0265 78.9339C74.0511 78.919 74.0806 78.9141 74.1088 78.9204C74.137 78.9267 74.1616 78.9435 74.1777 78.9675C74.4595 79.3686 74.831 79.6983 75.2627 79.9307C75.6943 80.163 76.1741 80.2915 76.6641 80.3059C76.6928 80.3059 76.7205 80.3169 76.7413 80.3367C76.7622 80.3565 76.7746 80.3836 76.7761 80.4123C76.7747 80.4401 76.7631 80.4664 76.7434 80.4861C76.7237 80.5057 76.6974 80.5174 76.6697 80.5187L76.6585 80.5467Z"
                fill="#263238"
            />
            <path
                d="M81.4801 74.3025C81.5174 74.3246 81.5599 74.3362 81.6033 74.3361C81.6569 74.3192 81.7022 74.2828 81.7303 74.234C81.7583 74.1852 81.7669 74.1277 81.7545 74.0729C81.6676 73.7424 81.502 73.4379 81.2719 73.1853C81.0417 72.9327 80.7539 72.7396 80.4329 72.6225C80.3794 72.6067 80.3219 72.6125 80.2727 72.6387C80.2234 72.6649 80.1865 72.7093 80.1697 72.7625C80.1537 72.8167 80.1594 72.875 80.1855 72.9251C80.2116 72.9752 80.2561 73.0133 80.3097 73.0313C80.5604 73.1316 80.7842 73.2892 80.9631 73.4914C81.142 73.6937 81.2711 73.935 81.3401 74.1961C81.3545 74.2228 81.3744 74.2461 81.3986 74.2644C81.4227 74.2828 81.4505 74.2958 81.4801 74.3025Z"
                fill="#263238"
            />
            <path
                d="M73.0856 67.353V69.3466C72.7335 69.465 72.4389 69.7117 72.2604 70.0376C72.082 70.3634 72.0327 70.7445 72.1224 71.105C72.5536 72.841 71.3048 75.2938 69.9104 76.145C69.9104 76.145 69.9552 75.4226 69.3504 75.5178C68.7456 75.613 68.4376 79.3706 69.4008 81.2914C69.4008 81.2914 67.8104 79.4658 67.284 75.4226C66.7912 71.6818 67.0488 68.6466 67.9616 67.3362L73.0856 67.353Z"
                fill="#263238"
            />
            <path
                d="M66.3321 75.6299C66.6521 76.7166 67.3889 77.6325 68.3817 78.1779C69.7033 78.8891 70.6945 77.7803 70.6217 76.3635C70.5321 75.0979 69.7481 73.1715 68.2809 73.0595C66.8137 72.9475 65.9513 74.2915 66.3321 75.6299Z"
                fill="#E4897B"
            />
            <path
                d="M67.0601 70.7466C67.0601 70.7466 65.6769 67.4258 69.3001 64.9898C72.9233 62.5538 81.5193 65.393 82.1241 67.3698C82.7289 69.3466 76.2273 72.9362 67.0601 70.7466Z"
                fill="#263238"
            />
            <path
                d="M69.2833 87.569L74.7657 90.5706L75.5161 86.4714L79.3577 88.969V94.205L75.2921 91.7802L72.4361 96.0586L66.7969 89.865L69.2833 87.569Z"
                fill="#0052CC"
            />
            <path
                d="M81.7355 130.115L77.4189 130.354C77.0607 130.374 76.7863 130.68 76.8062 131.038L76.9678 133.957C76.9877 134.315 77.2942 134.59 77.6524 134.57L81.969 134.331C82.3272 134.311 82.6015 134.004 82.5816 133.646L82.42 130.727C82.4001 130.369 82.0937 130.095 81.7355 130.115Z"
                fill="white"
            />
            <path
                opacity="0.5"
                d="M69.2833 87.569L74.7657 90.5706L75.5161 86.4714L79.3577 88.969V94.205L75.2921 91.7802L72.4361 96.0586L66.7969 89.865L69.2833 87.569Z"
                fill="white"
            />
            <path
                opacity="0.2"
                d="M73.0857 123.644C71.4561 126.097 66.2425 126.08 64.2825 124.725C63.9801 120.049 63.4033 114.931 62.7593 110.131L73.0857 123.644Z"
                fill="black"
            />
            <path
                d="M64.5008 104.761C64.5008 104.761 65.66 112.31 66.7968 113.783C67.9336 115.255 91.4368 128.107 91.4368 128.107L99.5624 127.519L103.298 131.803C103.298 131.803 92.4728 133.483 91.2912 133.159C90.5701 132.959 89.8705 132.689 89.2024 132.352C89.2024 132.352 64.5624 122.647 62.4904 120.43C60.4184 118.212 58.0608 106.827 58.0608 106.827L64.5008 104.761Z"
                fill="#E4897B"
            />
            <path
                d="M59.7185 91.1811C64.0193 90.2851 66.2425 108.132 66.2425 108.132L57.4449 110.372C57.4449 110.372 54.4265 92.2899 59.7185 91.1811Z"
                fill="#0052CC"
            />
            <path
                opacity="0.5"
                d="M59.7185 91.1811C64.0193 90.2851 66.2425 108.132 66.2425 108.132L57.4449 110.372C57.4449 110.372 54.4265 92.2899 59.7185 91.1811Z"
                fill="white"
            />
            <path d="M56.9297 109.947L66.7969 107.583V109.28L57.3721 112.013L56.9297 109.947Z" fill="#0052CC" />
            <path
                d="M90.72 221.387V144.622C90.7207 141.299 89.7023 138.057 87.8024 135.331L85.12 131.49H132.776L135.458 135.331C137.358 138.057 138.377 141.299 138.376 144.622V220.659"
                stroke="#263238"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M191.996 238.215C194.721 238.215 196.93 236.006 196.93 233.281C196.93 230.556 194.721 228.347 191.996 228.347C189.271 228.347 187.062 230.556 187.062 233.281C187.062 236.006 189.271 238.215 191.996 238.215Z"
                fill="#263238"
            />
            <path
                opacity="0.4"
                d="M194.466 233.281C194.467 232.792 194.323 232.314 194.052 231.907C193.781 231.5 193.396 231.183 192.945 230.995C192.494 230.807 191.997 230.758 191.518 230.852C191.038 230.947 190.598 231.182 190.252 231.527C189.906 231.872 189.67 232.312 189.574 232.791C189.478 233.271 189.527 233.767 189.714 234.219C189.901 234.671 190.217 235.057 190.623 235.328C191.03 235.6 191.507 235.745 191.996 235.745C192.32 235.746 192.641 235.682 192.941 235.559C193.24 235.435 193.513 235.254 193.742 235.025C193.971 234.796 194.153 234.524 194.277 234.225C194.402 233.926 194.466 233.605 194.466 233.281Z"
                fill="white"
            />
            <path
                d="M191.402 234.379C191.018 234.378 190.642 234.26 190.327 234.04C190.011 233.819 189.771 233.508 189.638 233.147L186.732 225.183H193.278V232.503C193.278 233 193.081 233.477 192.729 233.829C192.377 234.181 191.9 234.379 191.402 234.379Z"
                fill="#263238"
            />
            <path
                opacity="0.2"
                d="M191.402 234.379C191.018 234.378 190.642 234.26 190.327 234.04C190.011 233.819 189.771 233.508 189.638 233.147L186.732 225.183H193.278V232.503C193.278 233 193.081 233.477 192.729 233.829C192.377 234.181 191.9 234.379 191.402 234.379Z"
                fill="white"
            />
            <path
                d="M137.116 238.215C139.841 238.215 142.05 236.006 142.05 233.281C142.05 230.556 139.841 228.347 137.116 228.347C134.391 228.347 132.182 230.556 132.182 233.281C132.182 236.006 134.391 238.215 137.116 238.215Z"
                fill="#263238"
            />
            <path
                opacity="0.4"
                d="M139.586 233.281C139.587 232.792 139.443 232.314 139.172 231.907C138.901 231.5 138.516 231.183 138.065 230.995C137.614 230.807 137.117 230.758 136.638 230.852C136.158 230.947 135.718 231.182 135.372 231.527C135.026 231.872 134.79 232.312 134.694 232.791C134.598 233.271 134.647 233.767 134.834 234.219C135.021 234.671 135.337 235.057 135.743 235.328C136.15 235.6 136.627 235.745 137.116 235.745C137.44 235.746 137.761 235.682 138.061 235.559C138.36 235.435 138.632 235.254 138.862 235.025C139.091 234.796 139.273 234.524 139.397 234.225C139.522 233.926 139.586 233.605 139.586 233.281Z"
                fill="white"
            />
            <path
                d="M136.522 234.379C136.138 234.378 135.762 234.26 135.447 234.04C135.132 233.819 134.891 233.508 134.758 233.147L131.852 225.183H138.398V232.503C138.398 233 138.201 233.477 137.849 233.829C137.497 234.181 137.02 234.379 136.522 234.379Z"
                fill="#263238"
            />
            <path
                opacity="0.2"
                d="M136.522 234.379C136.138 234.378 135.762 234.26 135.447 234.04C135.132 233.819 134.891 233.508 134.758 233.147L131.852 225.183H138.398V232.503C138.398 233 138.201 233.477 137.849 233.829C137.497 234.181 137.02 234.379 136.522 234.379Z"
                fill="white"
            />
            <path
                d="M200.48 219.443V224.103C200.48 224.52 200.314 224.92 200.019 225.215C199.724 225.51 199.324 225.676 198.906 225.676H88.6256C88.2083 225.676 87.808 225.51 87.5129 225.215C87.2178 224.92 87.052 224.52 87.052 224.103V219.443H200.48Z"
                fill="#263238"
            />
            <path
                opacity="0.4"
                d="M200.48 219.443V224.103C200.48 224.52 200.314 224.92 200.019 225.215C199.724 225.51 199.324 225.676 198.906 225.676H88.6256C88.2083 225.676 87.808 225.51 87.5129 225.215C87.2178 224.92 87.052 224.52 87.052 224.103V219.443H200.48Z"
                fill="white"
            />
            <path
                opacity="0.2"
                d="M161.28 219.443V224.103C161.28 224.52 161.114 224.92 160.819 225.215C160.524 225.51 160.124 225.676 159.706 225.676H88.6256C88.2083 225.676 87.808 225.51 87.5129 225.215C87.2178 224.92 87.052 224.52 87.052 224.103V219.443H161.28Z"
                fill="white"
            />
            <path
                d="M152.236 238.215C154.961 238.215 157.17 236.006 157.17 233.281C157.17 230.556 154.961 228.347 152.236 228.347C149.511 228.347 147.302 230.556 147.302 233.281C147.302 236.006 149.511 238.215 152.236 238.215Z"
                fill="#263238"
            />
            <path
                opacity="0.6"
                d="M154.706 233.281C154.707 232.792 154.563 232.314 154.292 231.907C154.021 231.5 153.636 231.183 153.185 230.995C152.734 230.807 152.237 230.758 151.758 230.852C151.278 230.947 150.838 231.182 150.492 231.527C150.146 231.872 149.91 232.312 149.814 232.791C149.718 233.271 149.767 233.767 149.954 234.219C150.141 234.671 150.457 235.057 150.863 235.328C151.27 235.6 151.747 235.745 152.236 235.745C152.56 235.746 152.881 235.682 153.181 235.559C153.48 235.435 153.752 235.254 153.982 235.025C154.211 234.796 154.393 234.524 154.517 234.225C154.642 233.926 154.706 233.605 154.706 233.281Z"
                fill="white"
            />
            <path
                d="M151.642 234.379C151.258 234.378 150.882 234.26 150.567 234.04C150.252 233.819 150.011 233.508 149.878 233.147L146.972 225.183H153.518V232.503C153.518 233 153.321 233.477 152.969 233.829C152.617 234.181 152.14 234.379 151.642 234.379Z"
                fill="#263238"
            />
            <path
                opacity="0.4"
                d="M151.642 234.379C151.258 234.378 150.882 234.26 150.567 234.04C150.252 233.819 150.011 233.508 149.878 233.147L146.972 225.183H153.518V232.503C153.518 233 153.321 233.477 152.969 233.829C152.617 234.181 152.14 234.379 151.642 234.379Z"
                fill="white"
            />
            <path
                d="M97.356 238.215C100.081 238.215 102.29 236.006 102.29 233.281C102.29 230.556 100.081 228.347 97.356 228.347C94.6312 228.347 92.4224 230.556 92.4224 233.281C92.4224 236.006 94.6312 238.215 97.356 238.215Z"
                fill="#263238"
            />
            <path
                opacity="0.6"
                d="M99.8257 233.281C99.8268 232.792 99.6829 232.314 99.4122 231.907C99.1415 231.5 98.7562 231.183 98.305 230.995C97.8539 230.807 97.3571 230.758 96.8777 230.852C96.3983 230.947 95.9577 231.182 95.6118 231.527C95.2658 231.872 95.0301 232.312 94.9343 232.791C94.8386 233.271 94.8872 233.767 95.0739 234.219C95.2607 234.671 95.5772 235.057 95.9835 235.328C96.3897 235.6 96.8674 235.745 97.3561 235.745C97.6801 235.746 98.0011 235.682 98.3007 235.559C98.6003 235.435 98.8726 235.254 99.102 235.025C99.3314 234.796 99.5134 234.524 99.6376 234.225C99.7618 233.926 99.8257 233.605 99.8257 233.281Z"
                fill="white"
            />
            <path
                d="M96.7624 234.379C96.3777 234.378 96.0024 234.26 95.687 234.04C95.3716 233.819 95.1313 233.508 94.9984 233.147L92.092 225.183H98.6384V232.503C98.6384 233 98.4408 233.477 98.089 233.829C97.7372 234.181 97.26 234.379 96.7624 234.379Z"
                fill="#263238"
            />
            <path
                opacity="0.4"
                d="M96.7624 234.379C96.3777 234.378 96.0024 234.26 95.687 234.04C95.3716 233.819 95.1313 233.508 94.9984 233.147L92.092 225.183H98.6384V232.503C98.6384 233 98.4408 233.477 98.089 233.829C97.7372 234.181 97.26 234.379 96.7624 234.379Z"
                fill="white"
            />
            <path d="M152.93 183.295H100.71V219.443H152.93V183.295Z" fill="#0052CC" />
            <path opacity="0.4" d="M152.93 183.295H100.71V219.443H152.93V183.295Z" fill="white" />
            <path opacity="0.2" d="M124.942 183.295H100.71V219.443H124.942V183.295Z" fill="white" />
            <path
                opacity="0.2"
                d="M122.651 211.799H119.622C119.337 211.799 119.106 212.03 119.106 212.315V216.453C119.106 216.737 119.337 216.968 119.622 216.968H122.651C122.936 216.968 123.166 216.737 123.166 216.453V212.315C123.166 212.03 122.936 211.799 122.651 211.799Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M117.186 211.799H114.156C113.871 211.799 113.641 212.03 113.641 212.315V216.453C113.641 216.737 113.871 216.968 114.156 216.968H117.186C117.47 216.968 117.701 216.737 117.701 216.453V212.315C117.701 212.03 117.47 211.799 117.186 211.799Z"
                fill="black"
            />
            <path opacity="0.4" d="M141.702 183.295H138.751V187.064H141.702V183.295Z" fill="black" />
            <path opacity="0.2" d="M149.772 198.729H129.444V203.455H149.772V198.729Z" fill="black" />
            <path opacity="0.2" d="M109.239 185.177H102.29V192.126H109.239V185.177Z" fill="black" />
            <path d="M158.262 147.147H106.042V183.295H158.262V147.147Z" fill="#0052CC" />
            <path opacity="0.4" d="M158.262 147.147H106.042V183.295H158.262V147.147Z" fill="black" />
            <path opacity="0.2" d="M130.273 147.147H106.042V183.295H130.273V147.147Z" fill="white" />
            <path
                opacity="0.2"
                d="M127.988 175.651H124.958C124.674 175.651 124.443 175.882 124.443 176.167V180.305C124.443 180.59 124.674 180.82 124.958 180.82H127.988C128.273 180.82 128.503 180.59 128.503 180.305V176.167C128.503 175.882 128.273 175.651 127.988 175.651Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M122.522 175.651H119.493C119.208 175.651 118.978 175.882 118.978 176.167V180.305C118.978 180.59 119.208 180.82 119.493 180.82H122.522C122.807 180.82 123.038 180.59 123.038 180.305V176.167C123.038 175.882 122.807 175.651 122.522 175.651Z"
                fill="black"
            />
            <path opacity="0.4" d="M147.034 147.147H144.082V150.916H147.034V147.147Z" fill="black" />
            <path opacity="0.2" d="M155.103 162.581H134.775V167.307H155.103V162.581Z" fill="black" />
            <path opacity="0.2" d="M114.576 149.029H107.626V155.978H114.576V149.029Z" fill="black" />
            <path d="M190.226 147.147H138.006V183.295H190.226V147.147Z" fill="#0052CC" />
            <path opacity="0.4" d="M190.226 147.147H138.006V183.295H190.226V147.147Z" fill="white" />
            <path opacity="0.2" d="M162.238 147.147H138.006V183.295H162.238V147.147Z" fill="white" />
            <path
                opacity="0.2"
                d="M159.953 175.651H156.923C156.639 175.651 156.408 175.882 156.408 176.167V180.305C156.408 180.59 156.639 180.82 156.923 180.82H159.953C160.237 180.82 160.468 180.59 160.468 180.305V176.167C160.468 175.882 160.237 175.651 159.953 175.651Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M154.487 175.651H151.458C151.173 175.651 150.942 175.882 150.942 176.167V180.305C150.942 180.59 151.173 180.82 151.458 180.82H154.487C154.772 180.82 155.002 180.59 155.002 180.305V176.167C155.002 175.882 154.772 175.651 154.487 175.651Z"
                fill="black"
            />
            <path opacity="0.4" d="M178.998 147.147H176.047V150.916H178.998V147.147Z" fill="black" />
            <path opacity="0.2" d="M187.068 162.581H166.74V167.307H187.068V162.581Z" fill="black" />
            <path opacity="0.2" d="M146.541 149.029H139.591V155.978H146.541V149.029Z" fill="black" />
            <path d="M172.166 110.999H119.946V147.147H172.166V110.999Z" fill="#0052CC" />
            <path opacity="0.2" d="M144.178 110.999H119.946V147.147H144.178V110.999Z" fill="white" />
            <path
                opacity="0.2"
                d="M141.887 139.503H138.858C138.573 139.503 138.342 139.734 138.342 140.018V144.157C138.342 144.441 138.573 144.672 138.858 144.672H141.887C142.172 144.672 142.402 144.441 142.402 144.157V140.018C142.402 139.734 142.172 139.503 141.887 139.503Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M136.427 139.503H133.398C133.113 139.503 132.882 139.734 132.882 140.018V144.157C132.882 144.441 133.113 144.672 133.398 144.672H136.427C136.712 144.672 136.942 144.441 136.942 144.157V140.018C136.942 139.734 136.712 139.503 136.427 139.503Z"
                fill="black"
            />
            <path opacity="0.4" d="M160.938 110.999H157.987V114.768H160.938V110.999Z" fill="black" />
            <path opacity="0.2" d="M169.008 126.433H148.68V131.159H169.008V126.433Z" fill="black" />
            <path opacity="0.2" d="M128.475 112.881H121.526V119.83H128.475V112.881Z" fill="black" />
            <path d="M186.161 183.295H133.941V219.443H186.161V183.295Z" fill="#263238" />
            <path opacity="0.2" d="M186.161 183.295H133.941V219.443H186.161V183.295Z" fill="white" />
            <path opacity="0.2" d="M158.172 183.295H133.941V219.443H158.172V183.295Z" fill="white" />
            <path
                opacity="0.2"
                d="M155.882 211.799H152.852C152.567 211.799 152.337 212.03 152.337 212.315V216.453C152.337 216.737 152.567 216.968 152.852 216.968H155.882C156.166 216.968 156.397 216.737 156.397 216.453V212.315C156.397 212.03 156.166 211.799 155.882 211.799Z"
                fill="black"
            />
            <path
                opacity="0.2"
                d="M150.416 211.799H147.386C147.102 211.799 146.871 212.03 146.871 212.315V216.453C146.871 216.737 147.102 216.968 147.386 216.968H150.416C150.701 216.968 150.931 216.737 150.931 216.453V212.315C150.931 212.03 150.701 211.799 150.416 211.799Z"
                fill="black"
            />
            <path opacity="0.4" d="M174.933 183.295H171.982V187.064H174.933V183.295Z" fill="black" />
            <path opacity="0.2" d="M183.002 198.729H162.674V203.455H183.002V198.729Z" fill="black" />
            <path opacity="0.2" d="M142.47 185.177H135.52V192.126H142.47V185.177Z" fill="black" />
            <rect x="0.5" y="0.5" width="279" height="279" rx="139.5" stroke="#EEEEEE" />
        </svg>
    )
}
