export const GoogleIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.6002 14.8447C28.6002 13.8733 28.5062 12.8707 28.3496 11.9307H14.7822V17.4767H22.5529C22.2396 19.2627 21.2056 20.8293 19.6702 21.832L24.3076 25.4353C27.0336 22.8973 28.6002 19.2 28.6002 14.8447Z"
                fill="#4280EF"
            />
            <path
                d="M14.7822 28.882C18.6675 28.882 21.9262 27.5973 24.3075 25.404L19.6702 21.832C18.3855 22.7093 16.7249 23.2106 14.7822 23.2106C11.0222 23.2106 7.85755 20.6726 6.69821 17.2886L1.93555 20.9546C4.37955 25.8113 9.33021 28.882 14.7822 28.882Z"
                fill="#34A353"
            />
            <path
                d="M6.69837 17.2573C6.10304 15.4713 6.10304 13.5286 6.69837 11.7426L1.9357 8.04529C-0.100964 12.1186 -0.100964 16.9126 1.9357 20.9546L6.69837 17.2573Z"
                fill="#F6B704"
            />
            <path
                d="M14.7822 5.82064C16.8189 5.78931 18.8242 6.57265 20.2969 7.98265L24.4015 3.84665C21.8009 1.40264 18.3542 0.0866452 14.7822 0.117978C9.33021 0.117978 4.37955 3.18865 1.93555 8.04531L6.69821 11.7426C7.85755 8.32731 11.0222 5.82064 14.7822 5.82064Z"
                fill="#E54335"
            />
        </svg>
    )
}
