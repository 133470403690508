import amplitude from 'amplitude-js'
import { AMPLITUDE_API_KEY } from '~/constants'
import { EVENTS_NAME } from '~/types'
import { delayedCallback } from '~/utils'
import { $log } from '~/utils/logger'

/**
 * Initialize SDK
 */
const init = () => {
    amplitude.getInstance('kelola').init(AMPLITUDE_API_KEY || '')
}

/**
 * Identify user
 * @param businessId
 * @param props
 * @param propsSetOnce
 */
const identify = (businessId: string, props?: Record<string, any>, propsSetOnce?: Record<string, any>) => {
    $log('set indentity', businessId)
    amplitude.getInstance('kelola').setUserId(businessId)
    if (!!props) amplitude.getInstance('kelola').setUserProperties(props)
    if (!!propsSetOnce) {
        const identify = new amplitude.Identify()
        Object.keys(propsSetOnce).forEach((key) => {
            identify.setOnce(key, propsSetOnce[key])
        })
        amplitude.getInstance('kelola').identify(identify)
    }
}

/**
 * Track event
 * @param name
 * @param props
 * @param callback
 */
const track = (name: EVENTS_NAME, props?: Record<string, any>, callback = () => false) => {
    const properties = { ...props, platform: 'web' }
    amplitude.getInstance('kelola').logEvent(name, properties)
    $log('send amplitude event', name, props)
    delayedCallback(callback)
}

/**
 * Reset user
 */
const reset = () => {
    amplitude.getInstance('kelola').setUserId(null)
}

const $amplitude = {
    init,
    identify,
    track,
    reset
}

export default $amplitude
