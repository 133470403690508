import { KelolaLogo } from '../Logo'

export const InitialPageLoad = () => {
    return (
        <>
            <div className="w-full h-screen flex items-center justify-center left-0 top-0 fixed transition-all">
                <div className="relative h-36 w-36">
                    <KelolaLogo></KelolaLogo>
                </div>
            </div>
        </>
    )
}
