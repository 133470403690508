import id from './id'

const locale = {
    id,
    en: {
        test: ''
    }
}

export default locale
