import locale from '~/locale'

export const DesktopPrompt = () => {
    return (
        <div className="w-full h-full bg-neutrals-100 flex justify-center items-center overflow-auto p-5">
            <div className="max-h-full w-full">
                <div className="mx-auto max-w-full">
                    <p className="text-center mb-6 mx-auto" style={{ maxWidth: 500 }}>
                        {locale.id.common.download_app.text}
                    </p>
                    <div className="flex justify-center">
                        <div>
                            <a
                                href="https://kelola.page.link/web"
                                target="_blank"
                                className="inline-block relative text-center overflow-hidden w-full rounded-md bg-primary-600 text-neutrals-light p-3 no-underline"
                                rel="noreferrer">
                                <span>{locale.id.common.download_app.button}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
