import { useState } from 'react'
import { responsiveBreakpointMap } from '~/constants'
import { useGetDeviceWidth } from '~/hooks'
import { Spacer } from '../Common'

interface SidebarProps {
    /**
     * Sidebar menu
     */
    children: JSX.Element | JSX.Element[]
    /**
     * Sidebar shrink state
     */
    shrink?: boolean
    /**
     * Responsive breakpoint
     */
    responsive?: keyof typeof responsiveBreakpointMap
    /**
     * Responsive show sidebar state
     */
    showMobileSidebar?: boolean
    /**
     * Prepend element at the start of sidebar
     */
    prepend?: JSX.Element
    /**
     * Append element at the end of sidebar
     */
    append?: JSX.Element
}

export const Sidebar = ({
    children,
    shrink = false,
    responsive = 'sm',
    showMobileSidebar = false,
    prepend,
    append
}: SidebarProps) => {
    const [deviceWidth, setDeviceWidth] = useState(0)
    useGetDeviceWidth(setDeviceWidth)

    const isResponsiveView = deviceWidth !== 0 && deviceWidth <= responsiveBreakpointMap[responsive]
    const isShrinkedView = !isResponsiveView && shrink

    return (
        <aside
            className={`flex flex-col flex-shrink-0 overflow-y-hidden border-r border-neutrals-200 bg-primary-50 h-full transition-all ${
                isShrinkedView ? 'w-15' : 'w-64'
            } ${
                isResponsiveView
                    ? `absolute z-30 ${showMobileSidebar ? 'left-0 opacity-100' : '-left-64 opacity-0'}`
                    : ''
            }`}>
            {prepend}
            <ul className={`overflow-y-auto grid grid-cols-1 gap-2 ${isShrinkedView ? 'py-2' : 'py-4 px-3'}`}>
                {children}
            </ul>
            {append && (
                <>
                    <Spacer></Spacer>
                    {append}
                </>
            )}
        </aside>
    )
}
