import { Fragment } from 'react'
import { Icon } from '../Icon'

interface BreadcrumbsItem {
    /**
     * Breadcrumbs item's text
     */
    text: string
    /**
     * Optional breadcrumbs item's icon
     */
    icon?: JSX.Element
    /**
     * Disable item
     */
    disabled?: boolean
    /**
     * Optional onclick event
     */
    onclick?: () => void
}

interface BreadcrumbsProps {
    /**
     * Items of breadcrumb
     */
    items?: BreadcrumbsItem[]
    /**
     * Background options
     */
    background?: boolean
}

export const Breadcrumbs = ({ items = [], background = true }: BreadcrumbsProps) => {
    const handleClick = (item: BreadcrumbsItem) => {
        if (!!item.disabled) {
            return
        }

        if (!item.onclick) {
            return
        }

        item.onclick()
    }

    return (
        <div
            className={`inline-flex flex-shrink-0 flex-grow-0 items-center rounded-lg ${
                background ? 'bg-neutrals-light px-5 py-3' : ''
            }`}
            aria-label="breadcrumbs">
            {items.map((item, key) => (
                <Fragment key={key}>
                    <span
                        key={'breadcrumb_' + key}
                        className={`flex items-center leading-normal ${
                            !!item.disabled ? 'text-neutrals-500' : 'text-neutrals-900'
                        }`}
                        role="button"
                        onClick={() => handleClick(item)}>
                        {!!item.icon && (
                            <>
                                {item.icon}
                                <span className="w-4"></span>
                            </>
                        )}
                        <span className="text-sm font-medium">{item.text}</span>
                    </span>
                    {key !== items.length - 1 && (
                        <span
                            className="text-neutrals-500 mx-4 inline-flex items-center"
                            key={'breadcrumb_chevron_' + key}>
                            <Icon name="chevron-right" size="lg"></Icon>
                        </span>
                    )}
                </Fragment>
            ))}
        </div>
    )
}
