import common from './common'

const id = {
    common,
    onboarding: {
        welcome: 'Selamat Datang!',
        step: {
            product: {
                title: '65% Tercapai',
                description: 'Tinggal 2 langkah lagi untuk memulai sukses besar!'
            },
            stock: {
                title: '95% Hampir Selesai !!',
                description: 'Tinggal selangkah lagi untuk memulai sukses besar! Ayo selesaikan.'
            }
        },
        business: {
            title: 'Bisnis berhasil dibuat!',
            description: 'Sip! Bisnis Anda telah terdaftar'
        },
        product: {
            title: 'Tambah barang pertama',
            description: 'Tambah barang untuk bisa mulai mencatat stok'
        },
        stock: {
            title: 'Coba masukan stok',
            description: 'Mulai catat stok masuk dan keluar'
        },
        action: {
            add_business: {
                title: 'Buat Bisnis',
                description: 'Buat bisnis baru? Klik disini'
            },
            join_business: {
                title: 'Gabung Bisnis',
                description: 'Punya kode undangan? Gabung disini'
            }
        }
    },
    business: {
        title: 'Pilih bisnis, buat baru, atau gabung',
        add_business: {
            title: 'Buat bisnis baru',
            name: 'Nama bisnis',
            name_placeholder: 'Masukan nama',
            category: 'Kategori bisnis',
            category_placeholder: 'Pilih Kategori',
            button: 'Buat Bisnis'
        },
        edit_business: {
            title: 'Ubah bisnis'
        },
        join_business: {
            title: 'Bergabung ke Bisnis',
            input_code: 'Masukkan kode undangan dibawah ini:',
            field_placeholder: 'Kode undangan',
            confirm_title: 'Gabung',
            confirm_text: 'Anda akan menjadi bagian dari tim '
        },
        no_business: 'Belum ada bisnis',
        create_or_join: 'Buat baru atau gabung bisnis',
        message: {
            saved: 'Bisnis berhasil disimpan!'
        }
    },
    profile: {
        edit_profile: {
            title: 'Ubah profile',
            name: 'Nama Lengkap',
            name_placeholder: 'Masukan nama',
            email: 'Email',
            phone_number: 'Nomor HP'
        },
        message: {
            required_name: 'Nama harus diisi'
        }
    },
    product: {
        choose_product: 'Pilih Barang',
        choose_product_empty: 'Pilih barang dan masukan kuantitas',
        search: {
            placeholder: 'Cari dari nama',
            tooltip: 'Buka scanner'
        },
        column: {
            image: 'Foto',
            name: 'Nama',
            price: 'Harga',
            buying_price: 'Harga Modal',
            selling_price: 'Harga Jual',
            sku: 'SKU',
            stock: 'Stok',
            quantity: 'Jumlah',
            latest_stock: 'Sisa',
            product_deleted: 'Barang Telah di Hapus',
            info_not_found: 'Informasi tidak tersedia',
            expired_date: 'Kadaluwarsa'
        },
        no_product: 'Barang Tidak Ada',
        add_product: {
            sku: 'Kode Barang',
            sku_placeholder: 'Kode Barang. Contoh: 12345',
            name: 'Nama Barang',
            name_placeholder: 'Masukan Nama Barang',
            initial_stock: 'Stok Awal',
            initial_stock_placeholder: 'Masukan Stok Awal',
            buying_price: 'Harga Modal',
            buying_price_placeholder: 'Masukan Harga Modal',
            selling_price: 'Harga Jual',
            selling_price_placeholder: 'Nama Harga',
            category: 'Kategori',
            category_placeholder: 'Pilih Kategori',
            add_selling_price: 'Tambah Harga Grosir',
            expired_date: 'Tanggal Kadaluwarsa',
            expired_date_placeholder: 'Pilih Tanggal',
            description: 'Deskripsi',
            description_placeholder: 'Masukan Deskripsi'
        },
        choose_selling_price: 'Pilih Harga Jual',
        selling_price: 'Harga Jual',
        total_product: 'Jumlah Barang',
        total_quantity: 'Jumlah Stok',
        total_buying_price: 'Total Harga Beli',
        total_selling_price: 'Total Harga Jual',
        message: {
            required_name: 'Nama barang wajib diisi',
            required_sku: 'SKU wajib diisi',
            required_quantity: 'Stok awal wajib diisi',
            invalid_input_qty: 'Stok yang dimasukan tidak valid',
            required_buying_price: 'Harga modal wajib diisi',
            required_selling_price: 'Harga jual wajib diisi',
            required_selling_price_name: 'Nama harga jual wajib diisi',
            required_selling_price_price: 'Harga jual wajib diisi'
        }
    },
    note: {
        title: 'Catatan',
        add: 'Tambahkan Catatan',
        no_note: 'Tidak ada catatan'
    },
    settings: {
        category: 'Kategori',
        language: 'Bahasa',
        switch_business: 'Pindah Bisnis'
    },
    category: {
        category_list: {
            title: 'Kategori Barang'
        },
        add_category: {
            button: 'Kategori Baru',
            title: 'Tambah Kategori Baru'
        },
        edit_category: {
            title: 'Ubah Kategori'
        },
        name: 'Nama Kategori',
        name_placeholder: 'Masukan nama Kategori',
        no_category: 'Tidak ada kategori'
    },
    staff: {
        add: 'Tambah Staff',
        edit: 'Ubah Staff',
        access: {
            stock: 'Stok',
            add_product: 'Tambah barang baru',
            edit_product: 'Ubah barang',
            delete_product: 'Hapus barang',
            view_capital_price: 'Lihat Harga Modal',
            add_category: 'Tambah kategori baru',
            edit_category: 'Ubah kategori',
            delete_category: 'Hapus kategori',
            add_supplier: 'Tambah vendor baru',
            edit_supplier: 'Ubah vendor',
            delete_supplier: 'Hapus vendor',
            add_customer: 'Tambah pembeli baru',
            edit_customer: 'Ubah pembeli',
            delete_customer: 'Hapus pembeli',
            add_staff: 'Tambah staff',
            edit_staff: 'Ubah staff',
            delete_staff: 'Hapus staff',
            change_business_profile: 'Ganti profil bisnis'
        },
        no_staff: 'Tidak ada staff.'
    },
    code: {
        title: 'Staff Baru Ditambahkan!',
        subtitle: 'Bagikan kode undangan dibawah ini untuk bergabung:',
        valid: 'Kode berlaku hanya 48 jam',
        share: 'Bagikan ke WhatsApp',
        copied: 'Telah di salin!',
        invalid: 'Kode salah!'
    },
    history: {
        no_history: 'Tidak ada riwayat.'
    },
    supplier: {
        add: {
            title: 'Tambah Vendor'
        },
        edit: {
            title: 'Ubah Vendor'
        },
        new: 'Vendor baru',
        no_supplier: 'Tidak ada vendor.',
        bank: {
            title: 'Detail Bank'
        },
        note: 'Catatan',
        form: {
            name: {
                label: 'Nama Vendor',
                placeholder: 'Masukan Nama Vendor',
                placheolder_not_mandatory: 'Masukkan nama (tidak wajib)',
                error: {
                    required: 'Nama tidak boleh kosong'
                }
            },
            contact: {
                label: 'Nomor Kontak',
                placeholder: '6281234567890',
                error: {
                    required: 'Nomor kontak tidak boleh kosong',
                    invalid: 'Nomor tidak valid. Harus lengkap dengan kode negara'
                }
            },
            bank_name: {
                label: 'Nama Bank',
                placeholder: 'Masukan Nama Bank'
            },
            bank_account_name: {
                label: 'Nama Pemilik Rekening',
                placeholder: 'Masukan Nama Pemilik Rekening'
            },
            bank_account_number: {
                label: 'Nomor Rekening',
                placeholder: 'Masukan Nomor Rekening'
            },
            note: {
                label: 'Catatan',
                placeholder: 'Tambah Catatan'
            }
        },
        choose: 'Pilih Vendor'
    },
    customer: {
        add: {
            title: 'Tambah Pembeli'
        },
        edit: {
            title: 'Ubah Pembeli'
        },
        new: 'Pembeli baru',
        no_customer: 'Tidak ada pembeli.',
        note: 'Catatan',
        form: {
            name: {
                label: 'Nama Pembeli',
                placeholder: 'Masukan Nama Pembeli',
                placheolder_not_mandatory: 'Masukkan nama (tidak wajib)',
                error: {
                    required: 'Nama tidak boleh kosong'
                }
            },
            contact: {
                label: 'Nomor Kontak',
                placeholder: '6281234567890',
                error: {
                    required: 'Nomor kontak tidak boleh kosong',
                    invalid: 'Nomor tidak valid. Harus lengkap dengan kode negara'
                }
            },
            address: {
                label: 'Alamat',
                placeholder: 'Masukan Alamat'
            },
            note: {
                label: 'Catatan',
                placeholder: 'Tambah Catatan'
            }
        },
        choose: 'Pilih Pembeli'
    },
    confirm_delete: 'Yakin ingin menghapus data ini?',
    report: {
        title: 'Laporan'
    },
    accounting: {
        title: 'Keuangan',
        view_report: 'Lihat Laporan Keuangan',
        dashboard_title: 'Laporan Keuangan',
        stats: 'Statistik',
        transaction_report: 'Laporan Transaksi',
        filter: {
            date: {
                placeholder: 'Pilih Tanggal',
                submit: 'Terapkan',
                cancel: 'Reset Filter'
            },
            timeframe: {
                day: 'Harian',
                week: 'Mingguan',
                month: 'Bulanan',
                year: 'Tahunan'
            }
        },
        total_sales: 'Total Penjualan',
        total_purchases: 'Total Pembelian',
        total_profit: 'Total Profit',
        assets_value: 'Nilai Aset Terkini',
        table: {
            date: 'Tanggal',
            sales: 'Penjualan',
            purchases: 'Pembelian',
            profit: 'Profit'
        },
        no_data: 'Tidak ada data.'
    },
    stock_report: {
        title: 'Laporan Stok',
        view: 'Lihat Laporan Stok',
        no_report: 'Tidak ada catatan stok di tanggal atau staff terpilih.',
        column: {
            total_product: 'Jenis Barang Terkini',
            total_product_quantity: 'Total Barang Terkini'
        }
    },
    subscription: {
        paywall_small: {
            title: 'Fitur Khusus Pengguna VIP',
            description:
                'Masa uji coba Anda untuk memakai fitur ini telah habis. Ayo dukung pengembangan aplikasi karya anak bangsa dengan cara berlangganan.',
            button: 'Lihat Paket Langganan'
        }
    },
    payment: {
        order: {
            title: 'Pembayaran'
        }
    }
}

export default id
