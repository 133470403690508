const common = {
    header: {
        business_name: 'Bisnis Anda'
    },
    download_app: {
        text: 'Untuk menggunakan Kelola versi web, silakan gunakan laptop atau PC.',
        button: 'Download versi mobile'
    },
    request_error: 'Terjadi kesalahan. Silahkan coba lagi! atau hubungi CS untuk melaporkan masalah anda',
    home: 'Beranda',
    product: 'Barang',
    add_product: 'Tambah Barang',
    edit_product: 'Ubah Barang',
    stock_in: 'Stok Masuk',
    stock_out: 'Stok Keluar',
    staff: 'Staff',
    stock_audit: 'Stok Audit',
    button: {
        save: 'Simpan',
        save_and_add_more: 'Simpan & Buat Lagi',
        submit: 'Simpan',
        done: 'Selesai',
        edit: 'Ubah',
        save_edit: 'Simpan Perubahan',
        yes: 'Ya',
        clear: 'Hapus',
        delete: 'Hapus',
        cancel: 'Batal'
    },
    select_date: 'Pilih Tanggal',
    select_action: 'Pilih Aksi',
    mandatory: ' *(Wajib)',
    not_mandatory: '(Tidak Wajib)',
    business: 'Bisnis',
    account: 'Akun',
    profile: 'Profil',
    setting: 'Pengaturan',
    contact_us: 'Hubungi Kami',
    logout: 'Keluar',
    category: 'Kategori',
    data: 'Data',
    history: 'Riwayat',
    message: {
        onboard_success: 'Hore!! Perjalanan anda telah dimulai!',
        successfully_saved: 'Data berhasil disimpan',
        successfully_deleted: 'Data berhasil dihapus',
        error_required_field: 'Kolom wajib tidak boleh kosong',
        no_access: 'Anda tidak mempunyai akses'
    },
    feedback: 'Berikan feedback',
    contact: 'Kontak',
    add: 'Tambah',
    supplier: 'Vendor',
    customer: 'Pembeli',
    random: 'Acak',
    scan: 'Scan',
    enter: 'Masuk dengan scan barcode',
    terms: {
        text: 'Dengan melanjutkan artinya Anda menyetujui',
        link: 'Kebijakan privasi'
    },
    others: 'Lainnya'
}

export default common
