import React, { useEffect, useState } from 'react'
import { Button } from './Button'
import { Icon } from '../Icon'
import { Spacer } from './Spacer'

interface AlertProps {
    /**
     * Alert message
     */
    message: string | JSX.Element
    /**
     * Alert message's title
     */
    title?: string
    /**
     * Type of alert
     */
    type?: keyof typeof alertTypeMap
    /**
     * Is alert opened
     */
    open?: boolean
    /**
     * Centerize alert to certain element
     */
    wrapper?: string
    /**
     * Show icon
     */
    icon?: boolean
    /**
     * Show confirm button
     */
    confirmButton?: boolean
    /**
     * Confirm button text
     */
    confirmButtonText?: string
    /**
     * Confirm button icon
     */
    confirmButtonIcon?: JSX.Element
    /**
     * Show close button
     */
    closeButton?: boolean
    /**
     * On open alert event
     */
    onOpen?: () => void
    /**
     * On confirm event
     */
    onConfirm?: () => void
    /**
     * On close event
     */
    onClose?: () => void
}

export const Alert = ({
    message,
    title,
    type = 'success',
    icon = true,
    open = false,
    confirmButton = false,
    confirmButtonText = '',
    confirmButtonIcon,
    closeButton = false,
    onConfirm,
    onClose
}: AlertProps) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(open)
    }, [open])

    const handleClose = () => {
        if (!onClose) {
            return
        }

        onClose()
    }

    const handeConfirm = () => {
        if (!onConfirm) {
            return
        }

        onConfirm()
    }

    return (
        <div
            aria-label="alert"
            data-testid="alert_component"
            className={`flex flex-col leading-normal bottom-4 rounded-lg p-4 w-full transition-all ${
                show ? 'opacity-100 flex' : 'opacity-0 hidden'
            } ${alertTypeMap[type]}`}>
            {!!title && (
                <div className="flex items-center mb-1.5">
                    {icon && (
                        <>
                            <AlertIcon type={type}></AlertIcon>
                            <div className="w-2"></div>
                        </>
                    )}
                    <h3 className="text-base font-medium">{title}</h3>
                    {!closeButton && (
                        <>
                            <Spacer></Spacer>
                            <div className="w-2" data-testid="close_button_icon"></div>
                            <Icon name="x" size="lg" onClick={() => handleClose()}></Icon>
                        </>
                    )}
                </div>
            )}
            <div className={`flex ${!title ? 'items-start' : 'items-center'}`}>
                {!title && icon && (
                    <>
                        <AlertIcon type={type}></AlertIcon>
                        <div className="w-2.5"></div>
                    </>
                )}
                {typeof message === 'string' ? <p className="text-sm">{message}</p> : message}
                {!title && closeButton && (
                    <>
                        <Spacer></Spacer>
                        <div className="w-2.5" data-testid="close_button_icon"></div>
                        <Icon name="x" size="lg" onClick={() => handleClose()}></Icon>
                    </>
                )}
            </div>
            {confirmButton && (
                <div className="mt-3">
                    <Button
                        text={confirmButtonText}
                        type={type}
                        icon={confirmButtonIcon}
                        onClick={() => handeConfirm()}></Button>
                </div>
            )}
        </div>
    )
}

const AlertIcon = ({ type }: { type: keyof typeof alertTypeMap }) => {
    const renderIcon = () => {
        if (type === 'success') {
            return <Icon name="check-circle" size="lg"></Icon>
        }

        if (type === 'error') {
            return <Icon name="x-circle" size="lg"></Icon>
        }

        return <Icon name="information-circle" size="lg"></Icon>
    }

    return <div className="flex-shrink-0 h-5 flex items-center">{renderIcon()}</div>
}

const alertTypeMap = {
    success: 'bg-green-50 text-green-700',
    error: 'bg-red-50 text-red-700',
    warning: 'bg-amber-50 text-amber-700',
    information: 'bg-blue-100 text-blue-700',
    tertiary: 'bg-neutrals-100 text-neutrals-900'
}
