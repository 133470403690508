import { useRouter } from 'next/router'
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import { useGetDeviceWidth } from '~/hooks'
import { localStore } from '~/utils/local-store'
import { pageMap } from '~/utils/page'
import { useAuth } from '../Auth/AuthProvider'
import { GoogleIcon } from '../Icon/GoogleIcon'
import { SyncIcon } from '../Icon/SyncIcon'
import { AuthLayout, Dashboard, InitialPageLoad } from '../Layout'
import { ToastWrapper } from '../Layout/ToastWrapper'
import { Modal } from '../Modal'
import { Button } from './Button'
import { Meta } from './Meta'
import { ToastProps } from './Toast'

export interface GlobalData {
    pageLoading: boolean
    setPageLoading: Dispatch<SetStateAction<boolean>>
    deviceWidth: number
    setDeviceWidth: Dispatch<SetStateAction<number>>
    toastProps: ToastProps
    setToastProps: Dispatch<SetStateAction<ToastProps>>
}

const GlobalDataContextDefaultValue: GlobalData = {
    pageLoading: false,
    setPageLoading: () => false,
    deviceWidth: 0,
    setDeviceWidth: () => false,
    toastProps: {
        message: ''
    },
    setToastProps: () => false
}

export const GlobalDataContext = createContext<GlobalData>(GlobalDataContextDefaultValue)

export const useGlobal = () => useContext(GlobalDataContext)

interface GlobalDataProviderProps {
    children: JSX.Element | JSX.Element[]
}

const GlobalDataProvider = ({ children }: GlobalDataProviderProps) => {
    const router = useRouter()
    const { isAuthenticating, profile, googleSignIn } = useAuth()
    const [pageLoading, setPageLoading] = useState(true)
    const [deviceWidth, setDeviceWidth] = useState(0)
    const [toastProps, setToastProps] = useState<ToastProps>({
        message: ''
    })
    const pageProps = pageMap[router.pathname]

    useGetDeviceWidth(setDeviceWidth)

    const globalDataValue: GlobalData = {
        pageLoading,
        setPageLoading,
        deviceWidth,
        setDeviceWidth,
        toastProps,
        setToastProps
    }

    const renderComponent = () => {
        // if device width is unknown return empty component
        if (!deviceWidth || !pageProps) {
            return <></>
        }

        // if page still loading return page load component
        if (isAuthenticating) {
            return <InitialPageLoad></InitialPageLoad>
        }

        // For auth page use auth layout
        if (pageProps.layout === 'fullscreen') {
            return <AuthLayout showMobile={pageProps.mobile}>{children}</AuthLayout>
        }

        const hideNavigation = pageProps.hideNavigation || router.query.is_onboard === 'true'

        return (
            <>
                <Dashboard pathname={router.pathname} hideNavigation={hideNavigation} showMobile={pageProps.mobile}>
                    {children}
                </Dashboard>
                {(!profile?.email || profile.email === '') && localStore.get('PLATFORM')?.data !== 'webview' && (
                    <Modal close={false} show header={false}>
                        <div className="w-full grid grid-cols-1 gap-4">
                            <div className="w-full flex justify-center items-center pb-4">
                                <SyncIcon></SyncIcon>
                            </div>
                            <h1 className="font-bold text-2xl text-center">Hubungkan Kelola dengan Google</h1>
                            <p className="text-sm text-center pb-4">
                                Untuk alasan keamanan dan kenyamanan, mulai Februari masuk aplikasi Kelola tidak lagi
                                bisa menggunakan WhatsApp. Silakan klik tombol dibawah ini untuk melanjutkan.
                            </p>
                            <Button
                                type="outlined"
                                onClick={() => {
                                    googleSignIn('sync_email')
                                }}
                                icon={<GoogleIcon></GoogleIcon>}
                                text="Lanjutkan Dengan Google"></Button>
                        </div>
                    </Modal>
                )}
            </>
        )
    }

    return (
        <GlobalDataContext.Provider value={globalDataValue}>
            <Meta title={pageProps.title}></Meta>
            {renderComponent()}
            <ToastWrapper></ToastWrapper>
        </GlobalDataContext.Provider>
    )
}

export default GlobalDataProvider
