import { createContext, useContext, useState } from 'react'

interface TableContextProps {
    /**
     * Minimum width of the table
     */
    minWidth?: number
    /**
     * Rounded style
     */
    rounded?: boolean
    /**
     * Shadow style
     */
    shadow?: boolean
    /**
     * Key of the sorts
     */
    sorts?: TableSort
    /**
     * Set the config sort
     */
    setConfigSort?: (sorts: TableSort) => void
    /**
     * Callback function for when the sort changes
     */
    onChange: (type: TableChangeType, context: TableChangeContext) => void
}

interface TableSort {
    multiple?: boolean
    items: {
        key: string
        active?: boolean
        direction?: 'asc' | 'desc'
    }[]
}

export type TableChangeType = 'sort'

export interface TableChangeContext {
    sorts: TableSort
}

const TableContext = createContext<TableContextProps | undefined>(undefined)

export const useTable = () => {
    const context = useContext(TableContext)
    if (!context) {
        throw new Error('useTableContext must be used within a Table component')
    }
    return context
}

interface TableProps extends Omit<TableContextProps, 'setConfigSort' | 'onChange'> {
    /**
     * Content
     */
    children: JSX.Element | JSX.Element[]
    /**
     * Callback function for when the sort changes
     */
    onChange?: (type: 'sort', context: TableChangeContext) => void
}

export const Table = ({
    children,
    minWidth,
    rounded = false,
    shadow = false,
    sorts,
    onChange = () => {}
}: TableProps) => {
    const [configSort, setConfigSort] = useState<TableSort | undefined>(() => ({
        multiple: !!sorts?.multiple,
        items:
            sorts?.items.map((item) => ({
                ...item,
                active: item.active ?? false,
                direction: item.direction ?? 'asc'
            })) || []
    }))

    const onTableChange = (type: 'sort', context: { sorts: TableSort }) => {
        if (type === 'sort') {
            setConfigSort(context.sorts)
        }

        onChange(type, context)
    }

    return (
        <TableContext.Provider value={{ minWidth, rounded, shadow, sorts: configSort, onChange: onTableChange }}>
            <div className="flex flex-col w-full">
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full w-full">
                        <div
                            className={`overflow-x-auto ${rounded ? 'sm:rounded-lg' : ''} ${shadow ? 'shadow-md' : ''}`}
                            style={{ minWidth }}>
                            <table className={`min-w-full border-collapse ${rounded ? 'sm:rounded-lg' : ''}`}>
                                {children}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </TableContext.Provider>
    )
}
