import { CSSProperties, ReactElement, useEffect, useRef, useState } from 'react'
import { useGetDeviceWidth, useOnClickOutside } from '~/hooks'
import { difference, isInViewport } from '~/utils'

const TOOLTIP_HIDE_OFFSET = -99999
let TOOLTIP_REPOSITIONING_COUNT = 0

type Trigger = 'hover' | 'click'
type Position = 'top' | 'right' | 'bottom' | 'left'

interface TooltipProps {
    /**
     * The trigger to show tooltip
     */
    trigger?: Trigger
    /**
     * Variant of tooltip
     */
    variant?: keyof typeof tooltipVariantMap
    /**
     * Position of the tooltip
     */
    position?: Position
    /**
     * Content of tooltip
     */
    children?: string | JSX.Element
    /**
     * tooltip content
     */
    content?: string | JSX.Element
    /**
     * Arrow size in pixel
     */
    arrowSize?: number
    /**
     * ID of tooltip
     */
    id?: string
    /**
     * className of tooltip
     */
    className?: string
}

export const Tooltip = ({
    trigger = 'hover',
    variant = 'primary',
    position = 'top',
    children = '',
    content = '',
    arrowSize = 8,
    id = 'tooltip-target-' + Date.now(),
    className = ''
}: TooltipProps): ReactElement => {
    const ref = useRef(null)
    const targetRef = useRef<null | HTMLDivElement>(null)
    const [style, setStyle] = useState<CSSProperties>({
        transform: `translate(${TOOLTIP_HIDE_OFFSET}px, 0)`
    })
    const [arrowStyle, setArrowStyle] = useState<CSSProperties>({})
    const [width, setWidth] = useState(0)

    useGetDeviceWidth(setWidth)

    useOnClickOutside(ref, () => {
        hide()
    })

    useEffect(() => {
        addEvents()
        return () => {
            removeEvents()
        }
    }, [width, position, trigger])

    const addEvents = () => {
        setStyle({
            transform: `translate(${TOOLTIP_HIDE_OFFSET}px, 0)`
        })

        const target = targetRef.current
        const tooltip = ref.current

        if (!tooltip || !target) {
            return
        }

        if (trigger === 'hover') {
            target.addEventListener('mouseenter', show)
            target.addEventListener('mouseleave', hide)
            return
        }

        target.addEventListener('click', toggle)
    }

    const removeEvents = () => {
        const target = targetRef.current

        if (trigger === 'hover') {
            target?.removeEventListener('mouseenter', show)
            target?.removeEventListener('mouseleave', hide)
            return
        }

        target?.removeEventListener('click', toggle)
    }

    const show = () => {
        const _target = targetRef.current
        const _tooltip = ref.current

        const target = _target!
        const tooltip = _tooltip!

        let data: ReturnType<typeof calculateVerticalPositon> = {
            left: 0,
            top: 0,
            width: undefined,
            pos: 'top'
        }

        if (position === 'top' || position === 'bottom') {
            data = calculateVerticalPositon(target, tooltip, position)
        }

        if (position === 'left' || position === 'right') {
            data = calculateHorizontalPosition(target, tooltip, position)
        }

        const arrowPosition = getArrowPosition(data.top, data.left, target, tooltip, data.pos)

        setArrowStyle({
            transform: `translate(${arrowPosition.left}px, ${arrowPosition.top}px)`,
            width: arrowSize,
            height: arrowSize,
            zIndex: -1
        })

        setStyle({
            transform: `translate(${data.left}px, ${data.top}px)`,
            visibility: 'visible',
            opacity: 1,
            width: data.width
        })
    }

    const hide = () => {
        setStyle({
            transform: `translate(${TOOLTIP_HIDE_OFFSET}px, ${0}px)`,
            visibility: 'hidden',
            opacity: 0
        })
    }

    const toggle = () => {
        const tooltip = ref.current as null | HTMLElement

        if (tooltip?.style.visibility === 'visible') {
            hide()
            return
        }

        show()
    }

    const calculateVerticalPositon = (
        target: HTMLElement,
        tooltip: HTMLElement,
        pos: Position
    ): {
        top: number
        left: number
        width: number | undefined
        pos: Position
    } => {
        const targetRect = target.getBoundingClientRect()
        const tooltipRect = tooltip.getBoundingClientRect()

        const isTargetYOffsetIsLess = targetRect.top < tooltipRect.top
        const diffY = difference(targetRect.top, tooltipRect.top)
        const top =
            pos === 'top'
                ? calculateTop(isTargetYOffsetIsLess, diffY, tooltipRect.height)
                : calculateBottom(isTargetYOffsetIsLess, diffY, targetRect.height)

        // if outside of viewport change the position
        const RealY = isTargetYOffsetIsLess ? tooltipRect.top + top : tooltipRect.top + top

        const inViewport = isInViewport(
            RealY,
            targetRect.left + tooltipRect.width,
            RealY + tooltipRect.height,
            targetRect.left - tooltipRect.width
        )

        if (!inViewport[pos] && TOOLTIP_REPOSITIONING_COUNT === 0) {
            TOOLTIP_REPOSITIONING_COUNT++
            const _pos = inViewport.bottom ? 'bottom' : 'top'
            return calculateVerticalPositon(target, tooltip, _pos)
        }

        TOOLTIP_REPOSITIONING_COUNT = 0

        const left = getHorizontalLeftOffset(targetRect, tooltipRect)
        const width = getWidth(targetRect, tooltipRect, pos)

        return {
            top,
            left,
            width,
            pos
        }
    }

    const calculateHorizontalPosition = (target: HTMLElement, tooltip: HTMLElement, pos: Position) => {
        const targetRect = target.getBoundingClientRect()
        const tooltipRect = tooltip.getBoundingClientRect()
        const tooltipXOffset = tooltipRect.left - TOOLTIP_HIDE_OFFSET
        const isTargetXOffsetIsLess = targetRect.left < tooltipXOffset
        const diffX = difference(targetRect.left, tooltipXOffset)
        const left =
            pos === 'left'
                ? calculateLeft(isTargetXOffsetIsLess, diffX, tooltipRect.width)
                : calculateRight(isTargetXOffsetIsLess, diffX, targetRect.width)

        // if outside of viewport change to vertical position
        const RealX = isTargetXOffsetIsLess ? tooltipXOffset - left : tooltipXOffset + left

        const inViewport = isInViewport(
            targetRect.top - tooltipRect.height,
            RealX + tooltipRect.width,
            targetRect.top + tooltipRect.height,
            RealX
        )

        if (!inViewport.left || !inViewport.right) {
            const _pos = inViewport.top ? 'top' : 'bottom'
            return calculateVerticalPositon(target, tooltip, _pos)
        }

        const top = getVerticalTopOffset(targetRect, tooltipRect)
        const width = getWidth(targetRect, tooltipRect, pos)

        return {
            left,
            top,
            width,
            pos
        }
    }

    const calculateTop = (isTargetYOffsetIsLess: boolean, diffY: number, height: number) => {
        return isTargetYOffsetIsLess ? (diffY + height + arrowSize) * -1 : (-diffY + height + arrowSize) * -1
    }

    const calculateBottom = (isTargetYOffsetIsLess: boolean, diffY: number, height: number) => {
        return isTargetYOffsetIsLess ? -diffY + height + arrowSize : diffY + height + arrowSize
    }

    const calculateLeft = (isTargetXOffsetIsLess: boolean, diffX: number, width: number) => {
        return isTargetXOffsetIsLess ? (diffX + width + arrowSize) * -1 : (-diffX + width + arrowSize) * -1
    }

    const calculateRight = (isTargetXOffsetIsLess: boolean, diffX: number, width: number) => {
        return isTargetXOffsetIsLess ? -diffX + width + arrowSize : diffX + width + arrowSize
    }

    const getHorizontalLeftOffset = (targetRect: DOMRect, tooltipRect: DOMRect) => {
        const targetWidth = targetRect.width
        const tooltipWidth = tooltipRect.width
        const resetTooltipXOffset = tooltipRect.left - TOOLTIP_HIDE_OFFSET
        const isTargetXOffsetIsLess = targetRect.left < resetTooltipXOffset
        const diffX = difference(targetRect.left, resetTooltipXOffset)
        const isTargetHasMoreWidth = targetWidth > tooltipWidth
        const diffWidthHalf = difference(targetWidth, tooltipWidth) / 2

        const xOffset = isTargetXOffsetIsLess ? -diffX : diffX
        const left = isTargetHasMoreWidth ? xOffset + diffWidthHalf : xOffset - diffWidthHalf

        const tooltipXOffset = resetTooltipXOffset + left
        const diffViewport = difference(tooltipXOffset, 0)
        return tooltipXOffset > 0 ? left : left + diffViewport
    }

    const getVerticalTopOffset = (targetRect: DOMRect, tooltipRect: DOMRect) => {
        const targetHeight = targetRect.height
        const tooltipHeight = tooltipRect.height
        const isTargetXOffsetIsLess = targetRect.top < tooltipRect.top
        const diffY = difference(targetRect.top, tooltipRect.top)
        const isTargetHasMoreHeight = targetHeight > tooltipHeight
        const diffHeightHalf = difference(targetHeight, tooltipHeight) / 2

        const yOffset = isTargetXOffsetIsLess ? -diffY : diffY
        const top = isTargetHasMoreHeight ? yOffset + diffHeightHalf : yOffset - diffHeightHalf

        const tooltipYOffset = targetRect.top + top
        const diffViewport = difference(tooltipYOffset, 0)
        return tooltipYOffset > 0 ? top : top + diffViewport
    }

    const getWidth = (targetRect: DOMRect, tooltipRect: DOMRect, pos: Position) => {
        let maxWidth = window.innerWidth

        if (pos === 'left' || pos === 'right') {
            maxWidth = maxWidth - targetRect.width
        }

        return tooltipRect.width > maxWidth ? maxWidth : undefined
    }

    const getArrowPosition = (
        movedYOffset: number,
        movedXOffset: number,
        target: HTMLElement,
        tooltip: HTMLElement,
        pos: Position
    ) => {
        const targetRect = target.getBoundingClientRect()
        const tooltipRect = tooltip.getBoundingClientRect()

        if (pos === 'top' || pos === 'bottom') {
            const targetXoffset = targetRect.left
            const targetHalfWidth = targetRect.width / 2
            const targetHalfXOffset = targetXoffset + targetHalfWidth
            const tooltipXOffset = movedXOffset + tooltipRect.left - TOOLTIP_HIDE_OFFSET
            const diffX = difference(targetHalfXOffset, tooltipXOffset)
            const arrowSizeHalf = arrowSize / 2
            const left = diffX - arrowSizeHalf
            const top = pos === 'top' ? tooltipRect.height - arrowSizeHalf : arrowSizeHalf * -1

            return {
                left,
                top
            }
        }

        const targetYoffset = targetRect.top
        const targetHalfHeight = targetRect.height / 2
        const targetHalfYOffset = targetYoffset + targetHalfHeight
        const tooltipYOffset = movedYOffset + tooltipRect.top
        const diffY = difference(targetHalfYOffset, tooltipYOffset)
        const arrowSizeHalf = arrowSize / 2
        const top = diffY - arrowSizeHalf
        const left = pos === 'left' ? tooltipRect.width - arrowSizeHalf : arrowSizeHalf * -1

        return {
            top,
            left
        }
    }

    return (
        <>
            <div
                className={`inline-block cursor-pointer ${className}`}
                id={id}
                ref={targetRef}
                data-testid="children_component">
                {children}
            </div>
            <div
                ref={ref}
                className={`inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium rounded-lg shadow-sm opacity-0 whitespace-pre-wrap ${tooltipVariantMap[variant]}`}
                role="tooltip"
                data-testid="tooltip_component"
                style={{ ...style, display: content === '' ? 'none' : undefined }}>
                {content}
                <div
                    className={`top-0 left-0 invisible absolute bg-inherit before:content-[''] before:transform before:rotate-45 before:w-full before:h-full before:absolute before:bg-inherit before:visible`}
                    style={arrowStyle}></div>
            </div>
        </>
    )
}

const tooltipVariantMap = {
    primary: 'text-neutrals-light bg-primary-700',
    secondary: 'text-neutrals-light bg-secondary-600',
    tertiary: 'text-neutrals-light bg-gray-900',
    success: 'text-neutrals-light bg-green-700',
    error: 'text-neutrals-light bg-red-700',
    warning: 'text-neutrals-light bg-amber-700',
    information: 'text-neutrals-light bg-blue-700'
}
