import React from 'react'

interface HorizontalScrollableWrapperProps {
    /**
     * Height of wrapper
     */
    height?: number
    /**
     * Gap of content
     */
    gap?: keyof typeof gapMap
    /**
     * Wrapper content
     */
    children?: JSX.Element | JSX.Element[]
}

export const HorizontalScrollableWrapper = ({ height, gap = 'md', children }: HorizontalScrollableWrapperProps) => {
    return (
        <div className="overflow-hidden h-9" style={{ height }}>
            <div
                className={`flex overflow-x-auto h-inherit overflow-y-hidden items-center box-content ${gapMap[gap]}`}
                style={{ paddingBottom: '17px' }}>
                {children}
            </div>
        </div>
    )
}

const gapMap = {
    xs: 'gap-2',
    sm: 'gap-3',
    md: 'gap-4',
    lg: 'gap-5',
    xl: 'gap-6'
}
