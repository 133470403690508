import { ChangeEvent, KeyboardEventHandler, MouseEventHandler, useState } from 'react'
import { Spacer } from '../Common'

interface TextFieldProps {
    /**
     * Label for input
     */
    label?: string
    /**
     * Append component beside the label
     */
    labelAppend?: JSX.Element
    /**
     * Type of input
     */
    type?: 'text' | 'email' | 'password' | 'tel'
    /**
     * Input name
     */
    name?: string
    /**
     * Variant of the text field
     */
    variant?: 'success' | 'error'
    /**
     * Variant of the caption
     */
    captionVariant?: 'success' | 'error'
    /**
     * SVG icon
     */
    icon?: JSX.Element
    /**
     * Position of icon
     */
    iconPosition?: 'left' | 'right'
    /**
     * Input placeholder
     */
    placeholder?: string
    /**
     * Wrapper classname
     */
    className?: string
    /**
     * Specify input size
     */
    size?: keyof typeof inputSizeMap
    /**
     * Value of input
     */
    value?: string
    /**
     * Prepend element before input
     */
    prepend?: JSX.Element | JSX.Element[]
    /**
     * Append element after input
     */
    append?: JSX.Element | JSX.Element[]
    /**
     * Disable input
     */
    disabled?: boolean
    /**
     * Define input width
     */
    width?: number
    /**
     * Define input id
     */
    id?: string
    /**
     * Input captions, can put success/error message here
     */
    captions?: string
    /**
     * Optional character counter
     */
    counter?: boolean
    /**
     * Maximum character length
     */
    maxLength?: number
    /**
     * Textfield text align
     */
    textAlign?: 'left' | 'right' | 'center'
    /**
     * Input filling the width
     */
    fullWidthInput?: boolean
    /**
     * Input font weight
     */
    fontWeight?: keyof typeof fontWeightMap
    /**
     * Input letter spacing
     */
    letterSpacing?: keyof typeof letterSpacingMap
    /**
     * Optional input event
     */
    onInput?: (value: string) => void
    /**
     * Optional click input event
     */
    onClick?: MouseEventHandler<HTMLDivElement>
    /**
     * Optional onKeyPress input event
     */
    onKeyPress?: KeyboardEventHandler<HTMLInputElement>
    /**
     * Optional max character limit event
     */
    onReachedMaxCount?: () => void
    /**
     * Optional on focus event
     */
    onFocus?: () => void
    /**
     * Optional on blur event
     */
    onBlur?: () => void
}

export const TextField = ({
    label,
    labelAppend,
    type = 'text',
    name,
    variant,
    captionVariant,
    icon,
    iconPosition = 'left',
    placeholder,
    className,
    size = 'md',
    value = '',
    prepend,
    append,
    disabled = false,
    width,
    id,
    captions = '',
    counter = false,
    maxLength = 100,
    textAlign = 'left',
    fullWidthInput,
    fontWeight = 'normal',
    letterSpacing = 'normal',
    onInput,
    onClick,
    onKeyPress,
    onReachedMaxCount,
    onFocus,
    onBlur
}: TextFieldProps) => {
    const [isFocus, setFocus] = useState(false)
    const characaterCount = value.length

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (!onInput || disabled) {
            return
        }

        onInput(e.target.value)

        if (e.target.value.length >= maxLength) {
            handleReachedMaxCount()
        }
    }

    const handleReachedMaxCount = () => {
        if (!onReachedMaxCount) {
            return
        }

        onReachedMaxCount()
    }

    const handleFocus = (_value: boolean) => {
        setFocus(_value)

        if (_value) {
            if (onFocus) onFocus()
            return
        }

        if (onBlur) onBlur()
    }

    return (
        <>
            <div className={className}>
                {!!label && (
                    <div className="mb-1 flex items-center">
                        <label className="text-sm text-neutrals-900 leading-normal font-medium block" htmlFor={id}>
                            {label}
                        </label>
                        {!!labelAppend && <div className="ml-1">{labelAppend}</div>}
                    </div>
                )}
                <div
                    className={`flex border rounded-lg w-full items-center relative ${inputSizeMap[size]} ${
                        fontWeightMap[fontWeight]
                    } ${letterSpacingMap[letterSpacing]}  ${
                        disabled
                            ? 'bg-neutrals-100 border-neutrals-400'
                            : !!variant
                            ? inputVariantMap[variant].input
                            : isFocus
                            ? 'border-primary-600 text-neutrals-900 bg-neutrals-light'
                            : `bg-neutrals-light text-neutrals-500 border-neutrals-400 ${
                                  value.length > 0 ? 'text-neutrals-800' : 'text-neutrals-500'
                              }`
                    }`}
                    onClick={onClick}
                    style={{
                        width: width || 'auto'
                    }}>
                    {prepend}
                    {iconPosition === 'left' && (
                        <span className={!!icon ? 'mr-2.5 flex-shrink-0 flex items-center' : ''}>{icon}</span>
                    )}
                    <input
                        className={`box-content flex-grow flex-shrink min-w-0 outline-none bg-opacity-0 bg-transparent placeholder:text-neutrals-400 disabled:text-neutrals-400 text-${textAlign} ${
                            heightMap[size]
                        } ${fullWidthInput ? 'w-full' : ''}`}
                        style={{ fontSize: 'inherit' }}
                        type={type}
                        name={name || ''}
                        disabled={disabled}
                        onFocus={() => handleFocus(true)}
                        onBlur={() => handleFocus(false)}
                        onKeyPress={onKeyPress}
                        value={value}
                        onInput={handleInput}
                        placeholder={placeholder || ''}
                        maxLength={maxLength}
                        id={id}
                        autoComplete="off"
                    />
                    {iconPosition === 'right' && (
                        <span className={!!icon ? 'ml-2.5 flex-shrink-0 flex items-center' : ''}>{icon}</span>
                    )}
                    {append}
                </div>
                {(captions.length > 0 || counter) && (
                    <div
                        data-testid="text-field-caption"
                        className={`flex mt-1 text-xs leading-normal text-${textAlign} ${
                            !!captionVariant
                                ? inputVariantMap[captionVariant].captions
                                : !!variant
                                ? inputVariantMap[variant].captions
                                : 'text-neutrals-500'
                        }`}>
                        {captions.length > 0 && <span>{captions}</span>}
                        <Spacer></Spacer>
                        {counter && (
                            <span>
                                {characaterCount}/{maxLength}
                            </span>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

const inputSizeMap = {
    sm: 'text-sm leading-none px-4 py-2.5',
    md: 'text-base leading-none px-4 py-2.5',
    lg: 'text-base leading-normal px-4 py-2.5'
}

const heightMap = {
    sm: 'h-3.5 py-0.5',
    md: 'h-4.5 py-0.5',
    lg: 'h-6.5 py-0.5'
}

const inputVariantMap = {
    success: {
        input: 'text-neutrals-900 border-green-500',
        captions: 'text-green-500'
    },
    error: {
        input: 'text-neutrals-900 border-red-500',
        captions: 'text-red-500'
    }
}

const fontWeightMap = {
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
    bold: 'font-bold'
}

const letterSpacingMap = {
    tighter: 'tracking-tighter',
    tight: 'tracking-tight',
    normal: 'tracking-normal',
    wide: 'tracking-wide',
    wider: 'tracking-wider',
    widest: 'tracking-widest'
}
