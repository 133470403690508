import { Icon } from '../Icon'

interface HeaderTitleBackButtonWrapperProps {
    /**
     * Header title
     */
    title?: string
    /**
     * Show back button
     */
    showBackButton?: boolean
    /**
     * Optional icon
     */
    icon?: JSX.Element
    /**
     * On click event
     */
    onClick?: () => void
}

export const HeaderTitleBackButtonWrapper = ({
    title,
    showBackButton = true,
    icon,
    onClick
}: HeaderTitleBackButtonWrapperProps) => {
    const handleClick = () => {
        if (!onClick) {
            return
        }

        onClick()
    }
    return (
        <div className="flex items-center mr-2.5 text-neutrals-900">
            {showBackButton && <Icon name="chevron-left" width={32} onClick={() => handleClick()}></Icon>}
            {icon!! && <div className={`${showBackButton && 'ml-2'} flex justify-center items-center`}>{icon}</div>}
            <span className={`font-bold text-neutrals-900 text-xl ${showBackButton || icon!! ? 'ml-3' : ''}`}>
                {title}
            </span>
        </div>
    )
}
