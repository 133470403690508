interface ToggleProps {
    /**
     * Text or element
     */
    children?: JSX.Element | string
    /**
     * toggle value
     */
    value?: boolean
    /**
     * Disable toggle
     */
    disabled?: boolean
    /**
     * Id for input
     */
    id?: string
    /**
     * Optional toggle changle event
     */
    onChange?: (value: boolean) => void
}

export const Toggle = ({ children, value = false, disabled = false, id, onChange = () => false }: ToggleProps) => {
    const handleClick = () => {
        if (disabled) {
            return
        }

        onChange(!value)
    }
    return (
        <div
            id={id}
            className={`inline-flex relative items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} `}
            role="button"
            onClick={handleClick}>
            <div
                className={`w-11 h-6 ${
                    !value
                        ? 'bg-neutrals-200 after:border-neutrals-100'
                        : disabled
                        ? 'bg-primary-300 after:translate-x-full after:border-neutrals-100'
                        : 'bg-primary-600 after:translate-x-full after:border-neutrals-light '
                } focus:outline-none focus:ring-4 focus:ring-primary-200 rounded-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-neutrals-light after:border after:rounded-full after:h-5 after:w-5 after:transition-all`}></div>
            <span className="ml-3 text-sm font-medium text-gray-800">{children}</span>
        </div>
    )
}
