import React from 'react'
import { Skeleton } from './BlockSkeleton'

interface SkeletonProps {
    /**
     * number of lines
     */
    count?: number
    /**
     * line skeleton size
     */
    size?: keyof typeof sizeMap
    /**
     * skeleton className
     */
    className?: string
    /**
     * skeleton wrapper ClassName
     */
    wrapperClassName?: string
}

export const LineSkeleton = ({ count = 1, className, size = 'base', wrapperClassName }: SkeletonProps) => {
    const floorValue = Math.floor(count)
    const decimalValue = count - floorValue
    const lastSkeletonWidth = +decimalValue.toFixed(1) * 100

    return (
        <div className={`w-full grid grid-cols-1 animate-pulse ${sizeMap[size].gap} ${wrapperClassName}`}>
            {[...Array(floorValue)].map((_, index) => (
                <Skeleton key={index} height={sizeMap[size].height} className={className} type={'line'} />
            ))}
            {lastSkeletonWidth > 0 && (
                <Skeleton
                    key={floorValue + 1}
                    height={sizeMap[size].height}
                    width={`${lastSkeletonWidth}%`}
                    className={className}
                    type={'line'}
                />
            )}
        </div>
    )
}

const sizeMap = {
    xs: {
        height: 'h-3',
        gap: 'gap-y-0.5'
    },
    sm: {
        height: 'h-3.5',
        gap: 'gap-y-0.5'
    },
    base: {
        height: 'h-4',
        gap: 'gap-y-1'
    },
    lg: {
        height: 'h-5',
        gap: 'gap-y-1'
    },
    xl: {
        height: 'h-6',
        gap: 'gap-y-1'
    },
    '2xl': {
        height: 'h-6',
        gap: 'gap-y-1.5'
    },
    '3xl': {
        height: 'h-8',
        gap: 'gap-y-1.5'
    },
    '4xl': {
        height: 'h-9',
        gap: 'gap-y-2'
    },
    '5xl': {
        height: 'h-12',
        gap: 'gap-y-2'
    }
}
