interface TableRowProps {
    children: JSX.Element | JSX.Element[]
    /**
     * Selected state
     */
    selected?: boolean
    /**
     * Custom class
     */
    customClass?: string
    /**
     * Optional onclick event
     */
    onClick?: () => void
}

export const TableRow = ({ children, selected = false, customClass = '', onClick }: TableRowProps) => {
    return (
        <tr
            data-selected={selected}
            className={`border-b border-neutrals-100 ${
                selected ? 'bg-primary-50' : !!onClick ? 'hover:bg-primary-50' : ''
            } ${customClass}`}
            role={!!onClick ? 'button' : undefined}
            onClick={() => {
                if (!!onClick) {
                    onClick()
                }
            }}>
            {children}
        </tr>
    )
}
