interface BadgeProps {
    /**
     * Text or label of badge
     */
    text: string
    /**
     * Circle badge
     */
    circle?: boolean
    /**
     * What type of badge
     */
    type?: keyof typeof badgeTypeMap
    /**
     * How big the badge
     */
    size?: keyof typeof badgeSizeMap
    /**
     * Set badge width manually
     */
    width?: number
    /**
     * Overwrite text color
     */
    color?: string
    /**
     * Overwrite background color
     */
    backgroundColor?: string
    /**
     * Icon for badge
     */
    icon?: JSX.Element
    /**
     * Icon position
     */
    iconPosition?: 'left' | 'right'
    /**
     * Enable background
     */
    background?: boolean
    /**
     * Optional click event
     */
    onClick?: () => void
}

export const Badge = ({
    text,
    circle = false,
    type = 'tertiary',
    size = 'md',
    width,
    color,
    backgroundColor,
    icon,
    iconPosition = 'left',
    background = true,
    onClick
}: BadgeProps) => {
    const handleClick = () => {
        if (!onClick) {
            return
        }

        onClick()
    }

    return (
        <>
            <div
                className={`inline-flex items-center justify-center relative text-center overflow-hidden leading-normal flex-shrink-0 flex-grow-0 ${
                    badgeTypeMap[type]
                } ${circle ? 'w-5 h-5 rounded-full text-xs' : badgeSizeMap[size].wrap}`}
                style={{
                    width,
                    color,
                    backgroundColor: !background ? 'transparent' : backgroundColor
                }}
                onClick={() => handleClick()}
                role={!!onClick ? 'button' : undefined}
                aria-label="badge"
                data-testid="badge_component">
                {!!icon && iconPosition === 'left' && (
                    <>
                        {icon}
                        <div className="w-1"></div>
                    </>
                )}
                <span className="inline-block font-medium">{text}</span>
                {!!icon && iconPosition === 'right' && (
                    <>
                        <div className="w-1"></div>
                        {icon}
                    </>
                )}
            </div>
        </>
    )
}

const badgeTypeMap = {
    tertiary: 'bg-neutrals-200 text-neutrals-900',
    success: 'bg-green-100 text-green-800',
    error: 'bg-red-100 text-red-800',
    warning: 'bg-amber-100 text-amber-800',
    information: 'bg-blue-100 text-blue-800'
}

const badgeSizeMap = {
    sm: {
        wrap: 'px-1 py-0 leading-0 text-xs rounded-md'
    },
    md: {
        wrap: 'px-2.5 py-0.5 text-xs rounded-md'
    },
    lg: {
        wrap: 'px-3 py-0.5 text-sm rounded-md'
    }
}
