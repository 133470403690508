import React, { CSSProperties } from 'react'
import { COLORS } from '~/constants/colors'

export interface TypographyProps {
    /**
     * className of the Typography
     */
    className?: string
    /**
     * text color
     */
    color?: string
    /**
     * size of text
     */
    size?: keyof typeof fontSizeMap

    /**
     * font Weight of text
     */
    fontWeight?: keyof typeof fontWeightMap
    /**
     * style of typography
     */
    style?: CSSProperties
    /**
     * text content
     * @deprecated pass text as children
     */
    text?: string
    /**
     * text content
     */
    children?: string | React.ReactNode
    /**
     * variant of typography
     */
    variant?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    /**
     * text overflow type
     */
    overflow?: keyof typeof overflowTypeMap
    /**
     * text transform
     */
    textTransform?: keyof typeof transformTextMap
}

export const Typography = ({
    className = '',
    color = COLORS.MAIN.NEUTRALS['900'],
    fontWeight = 'normal',
    size = 'base',
    style,
    text,
    children,
    variant = 'p',
    overflow = 'clip',
    textTransform = 'none'
}: TypographyProps) => {
    const VariantTag = variant

    return (
        <VariantTag
            className={`${fontSizeMap[size]} ${fontWeightMap[fontWeight]} ${className} ${overflowTypeMap[overflow]} ${transformTextMap[textTransform]}`}
            style={{ color, ...style }}>
            {text || children}
        </VariantTag>
    )
}

const overflowTypeMap = {
    truncate: 'truncate',
    ellipsis: 'text-ellipsis overflow-hidden',
    clip: 'text-clip overflow-hidden'
}

const transformTextMap = {
    uppercase: 'uppercase',
    lowercase: 'lowercase',
    capitalize: 'capitalize',
    none: 'normal-case'
}

const fontSizeMap = {
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl',
    '4xl': 'text-4xl',
    '5xl': 'text-5xl',
    '6xl': 'text-6xl',
    '7xl': 'text-7xl',
    '8xl': 'text-8xl',
    '9xl': 'text-9xl'
}

const fontWeightMap = {
    thin: 'font-thin',
    light: 'font-light',
    normal: 'font-normal',
    medium: 'font-medium',
    semibold: 'font-semibold',
    bold: 'font-bold',
    black: 'font-black'
}
