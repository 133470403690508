import React from 'react'

interface MobileTableLineWrapperProps {
    /**
     * Wrapper content
     */
    children?: JSX.Element | JSX.Element[]
    /**
     * Role of this element
     */
    role?: string
    /**
     * Wrapper custom width
     */
    width?: number
    /**
     * Optional on click event
     */
    onClick?: () => void
}

export const MobileTableLineWrapper = ({ children, role, width, onClick }: MobileTableLineWrapperProps) => {
    const handleClick = () => {
        if (!onClick) {
            return
        }

        onClick()
    }

    return (
        <div
            onClick={() => handleClick()}
            className="bg-white p-4 sm:p-8 max-w-full w-full border-b border-neutrals-200 last-of-type:border-b-0"
            role={role}
            style={{
                width
            }}>
            {children}
        </div>
    )
}
