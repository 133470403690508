import React from 'react'

interface HeaderWrapperProps {
    /**
     * Wrapper content
     */
    children?: JSX.Element | JSX.Element[]
    /**
     * Show header on webview
     */
    showOnWebview?: boolean
}

export const HeaderWrapper = ({ children, showOnWebview = true }: HeaderWrapperProps) => {
    if (!showOnWebview) {
        return <></>
    }

    return (
        <header className="web-header py-5 pl-5 pr-4 sm:pr-6 bg-neutrals-light border-b border-neutrals-200 flex items-center h-header">
            {children}
        </header>
    )
}
