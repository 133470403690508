import { paddingSizeMap } from '~/constants'
import { Size } from '~/types'

interface TableColumnProps {
    /**
     * Tabel column content
     */
    children: JSX.Element | JSX.Element[] | string | number
    /**
     * Set width manually
     */
    width?: number
    /**
     * Padding left size
     */
    paddingLeft?: Size
    /**
     * Padding top size
     */
    paddingTop?: Size
    /**
     * Padding right size
     */
    paddingRight?: Size
    /**
     * Padding bottom size
     */
    paddingBottom?: Size
    /**
     * Vertical alignment
     */
    valign?: 'top' | 'middle' | 'bottom'
}

export const TableColumn = ({
    children,
    width,
    paddingLeft = 'md',
    paddingTop = 'md',
    paddingRight = 'md',
    paddingBottom = 'md',
    valign = 'middle'
}: TableColumnProps) => {
    return (
        <td
            valign={valign}
            className={`whitespace-nowrap text-sm text-left text-neutrals-900 leading-normal ${paddingSizeMap[paddingLeft].left} ${paddingSizeMap[paddingTop].top} ${paddingSizeMap[paddingRight].right} ${paddingSizeMap[paddingBottom].bottom}`}
            style={{
                width
            }}>
            {children}
        </td>
    )
}
