import { delayedCallback } from '~/utils'
import { Toast } from '../Common'
import { useGlobal } from '../Common/GlobalDataProvider'

export const ToastWrapper = () => {
    const { toastProps, setToastProps } = useGlobal()

    const handleClose = () => {
        setToastProps({
            ...toastProps,
            open: false
        })

        if (toastProps.onClose) {
            toastProps.onClose()
        }

        delayedCallback(() => {
            setToastProps({
                message: ''
            })
        }, 500)
    }

    return <Toast {...toastProps} onClose={handleClose}></Toast>
}
