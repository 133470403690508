import { useMemo } from 'react'
import { paddingSizeMap } from '~/constants'
import { COLORS } from '~/constants/colors'
import { Size } from '~/types'
import { Icon } from '../Icon'
import { useTable } from './Table'

interface TableHeadingProps {
    /**
     * Content of table heading
     */
    children: JSX.Element | JSX.Element[] | string | number
    /**
     * Set width manually
     */
    width?: number
    /**
     * Padding left size
     */
    paddingLeft?: Size
    /**
     * Padding top size
     */
    paddingTop?: Size
    /**
     * Padding right size
     */
    paddingRight?: Size
    /**
     * Padding bottom size
     */
    paddingBottom?: Size
    /**
     * Sticky position
     */
    sticky?: boolean
    /**
     * Key name of the column
     */
    keyName?: string
}

export const TableHeading = ({
    children,
    width,
    paddingLeft = 'md',
    paddingTop = 'md',
    paddingRight = 'md',
    paddingBottom = 'md',
    sticky = false,
    keyName
}: TableHeadingProps) => {
    const { sorts, onChange } = useTable()
    const sortEl = useMemo(() => sorts?.items.find((el) => el.key === keyName), [sorts, keyName])

    const handleSortActive = (): typeof sortEl | undefined => {
        if (!sortEl) return

        if (!sortEl.active) {
            return {
                ...sortEl,
                active: true,
                direction: 'asc'
            }
        } else if (sortEl.active && sortEl.direction === 'asc') {
            return {
                ...sortEl,
                active: true,
                direction: 'desc'
            }
        } else {
            return {
                ...sortEl,
                active: false,
                direction: 'asc'
            }
        }
    }

    const onSort = () => {
        if (!sorts || !sortEl) return

        const newSortsEl = handleSortActive()
        if (!newSortsEl) return

        const newSorts = {
            ...sorts,
            items: [
                ...sorts.items.map((item) => {
                    if (!sorts.multiple) {
                        item.active = false
                        item.direction = 'asc'
                    }

                    if (item.key === newSortsEl.key) {
                        return newSortsEl
                    }

                    return item
                })
            ]
        }

        onChange('sort', { sorts: newSorts })
    }

    return (
        <th
            scope="col"
            className={`text-left tracking-wider text-sm text-neutrals-700 capitalize font-bold leading-normal ${
                paddingSizeMap[paddingLeft].left
            } ${paddingSizeMap[paddingTop].top} ${paddingSizeMap[paddingRight].right} ${
                paddingSizeMap[paddingBottom].bottom
            } ${sticky && 'relative'}`}
            style={{
                width
            }}>
            {
                <div className={`${sticky && 'sticky top-0'} bg-white z-10 flex`}>
                    {children}
                    {!!sortEl && (
                        <div className="flex items-center justify-center ml-1">
                            <Icon
                                color={sortEl.active ? COLORS.MAIN.PRIMARY[500] : COLORS.MAIN.NEUTRALS[300]}
                                name={sortEl.direction === 'asc' ? 'chevron-down' : 'chevron-up'}
                                size="lg"
                                onClick={onSort}
                            />
                        </div>
                    )}
                </div>
            }
        </th>
    )
}
