import { ReactElement, useState } from 'react'
import { OTPForm, OTPFormProps } from './OTPForm'
import { PhoneNumberForm, PhoneNumberFormProps } from './PhoneNumberForm'

type TLoginProps = PhoneNumberFormProps & OTPFormProps

export const Login = ({
    phoneFormTitle,
    defaultCountryCode,
    phoneNumberPlaceholder,
    defaultPhoneNumber,
    phoneFormButtonText,
    bottomText,
    phoneNumberError,
    selectCountryDropdownMaxHeight,
    phoneNumberFormLoading,
    editPhoneNumber,
    OTPTimer,
    OTPHeader,
    OTPButtonText,
    OTPFooter,
    OTPError,
    channel,
    showAlternative,
    writeOTP,
    OTPFormLoading,
    onSelectCountryCode,
    onInputPhoneNumber,
    onSubmitPhoneNumber,
    onPhoneNumberError,
    onResendOTP,
    onClickChangeNumber,
    onSendWithAlternative,
    onInputOTP,
    onSubmitOTP,
    onOTPError
}: TLoginProps): ReactElement => {
    const [countryCode, setCountryCode] = useState(defaultCountryCode || '')
    const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber || '')
    const [step, setStep] = useState(1)

    if (step === 1) {
        return (
            <PhoneNumberForm
                countryCode={countryCode}
                phoneNumber={phoneNumber}
                setCountryCode={setCountryCode}
                setPhoneNumber={setPhoneNumber}
                setStep={setStep}
                phoneFormTitle={phoneFormTitle}
                phoneNumberPlaceholder={phoneNumberPlaceholder}
                phoneFormButtonText={phoneFormButtonText}
                bottomText={bottomText}
                phoneNumberError={phoneNumberError}
                selectCountryDropdownMaxHeight={selectCountryDropdownMaxHeight}
                phoneNumberFormLoading={phoneNumberFormLoading}
                editPhoneNumber={editPhoneNumber}
                onSelectCountryCode={onSelectCountryCode}
                onInputPhoneNumber={onInputPhoneNumber}
                onSubmitPhoneNumber={onSubmitPhoneNumber}
                onPhoneNumberError={onPhoneNumberError}></PhoneNumberForm>
        )
    }

    return (
        <OTPForm
            phoneNumber={phoneNumber}
            countryCode={countryCode}
            OTPTimer={OTPTimer}
            OTPHeader={OTPHeader}
            OTPButtonText={OTPButtonText}
            OTPFooter={OTPFooter}
            channel={channel}
            OTPError={OTPError}
            showAlternative={showAlternative}
            writeOTP={writeOTP}
            OTPFormLoading={OTPFormLoading}
            editPhoneNumber={editPhoneNumber}
            onResendOTP={onResendOTP}
            onClickChangeNumber={onClickChangeNumber}
            onSendWithAlternative={onSendWithAlternative}
            onInputOTP={onInputOTP}
            onSubmitOTP={onSubmitOTP}
            onOTPError={onOTPError}
            setStep={setStep}></OTPForm>
    )
}
