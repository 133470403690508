interface SkeletonProps {
    /**
     * height of skeleton
     */
    height?: string
    /**
     * width of skeleton
     */
    width?: string
    /**
     * border Radius of skeleton
     */
    borderRadius?: string
    /**
     * skeleton className
     */
    className?: string
    /**
     * skeleton wrapper ClassName
     */
    wrapperClassName?: string
    /**
     * skeleton type
     */
    type?: 'block' | 'line'
}

export const Skeleton = ({
    borderRadius = '4px',
    height = '100px',
    width,
    className,
    wrapperClassName,
    type = 'block'
}: SkeletonProps) => {
    if (type === 'block') {
        const _height = height || width
        const _width = width || height
        return (
            <div className={`w-full grid grid-cols-1 animate-pulse ${wrapperClassName}`}>
                <div
                    className={`bg-neutrals-200 ${className}`}
                    style={{ height: _height, width: _width, borderRadius }}></div>
            </div>
        )
    }

    return <div className={`bg-neutrals-200 rounded-lg ${height} ${className}`} style={{ width }}></div>
}
