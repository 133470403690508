interface RadioProps {
    /**
     * Specify radio width
     */
    width?: number
    /**
     * Specify radio height
     */
    height?: number
    /**
     * Specify radio type
     */
    type?: keyof typeof typeMap
    /**
     * radio value
     */
    value?: boolean
    /**
     * Optional radio label
     */
    label?: string | JSX.Element
    /**
     * Disable radio
     */
    disabled?: boolean
    /**
     * Id for input
     */
    id?: string
    /**
     * Optional radio select event
     */
    onSelect?: (value: boolean) => void
}

export const Radio = ({ id, label, width, height, type = 'normal', disabled, onSelect, value }: RadioProps) => {
    const handleChange = (value: boolean) => {
        if (!onSelect || !!disabled) {
            return
        }

        onSelect(value)
    }

    return (
        <div className="flex">
            <div className="flex items-center" onChange={() => handleChange(!value)}>
                <input
                    id={id}
                    aria-describedby={id}
                    disabled={!!disabled}
                    type="radio"
                    checked={!!value}
                    style={{
                        width,
                        height
                    }}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    autoComplete="off"
                />
                {!!label &&
                    (typeof label === 'string' ? (
                        <label htmlFor={id} className={`text-sm ml-3 font-medium ${typeMap[type]}`}>
                            {label}
                        </label>
                    ) : (
                        label
                    ))}
            </div>
        </div>
    )
}

const typeMap = {
    primary: 'text-primary',
    secondary: 'text-secondary',
    normal: 'text-gray-900',
    warning: 'text-warning'
}
