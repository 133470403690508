import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '~/hooks'
import { Dropdown, DropdownListItem } from '../Dropdown'
import { IconLoaderCircle } from '../Icon'

interface AutocompleteProps {
    /**
     * Label of the textfield
     */
    label?: string
    /**
     * Append component beside the label
     */
    labelAppend?: JSX.Element
    /**
     * Variant of the text field
     */
    variant?: 'success' | 'error'
    /**
     * Loading state
     */
    loading?: boolean
    /**
     * Disabled state
     */
    disabled?: boolean
    /**
     * Define input width
     */
    width?: number
    /**
     * Specify input size
     */
    size?: keyof typeof inputSizeMap
    /**
     * Value of the field
     */
    value?: string
    /**
     * Text field placeholder
     */
    placeholder?: string
    /**
     * Icon
     */
    icon?: JSX.Element
    /**
     * Icon position
     */
    iconPosition?: 'left' | 'right'
    /**
     * List of items selectable items
     */
    items?: any[]
    /**
     * If item is object, show this text
     */
    itemText?: any
    /**
     * If item is object, this is the value
     */
    itemValue?: any
    /**
     * Define input id
     */
    id?: string
    /**
     * Input captions, can put success/error message here
     */
    captions?: string
    /**
     * Wrapper classname
     */
    className?: string
    /**
     * Min width of dropdown
     */
    dropdownMinWidth?: number
    /**
     * Match dropdown width to select parent
     */
    fitToParentBox?: boolean
    /**
     * Input filling the width
     */
    fullWidthInput?: boolean
    /**
     * Append element to dropdown
     */
    append?: JSX.Element
    /**
     * Prepend element to dropdown
     */
    prepend?: JSX.Element
    /**
     * On input event
     */
    onInput?: (value: string) => void
    /**
     * On select event
     */
    onSelect?: (value: string, item: any) => void
    /**
     * On not select event
     */
    onNotSelect?: () => void
    /**
     * On open dropdown event
     */
    onOpen?: () => void
}

export const Autocomplete = ({
    label,
    labelAppend,
    variant,
    loading = false,
    size = 'md',
    width,
    disabled = false,
    value = '',
    placeholder = '',
    prepend,
    append,
    icon,
    iconPosition = 'left',
    items = [],
    itemText,
    itemValue,
    id,
    captions = '',
    className,
    dropdownMinWidth,
    fitToParentBox = false,
    fullWidthInput,
    onInput,
    onSelect,
    onNotSelect,
    onOpen
}: AutocompleteProps) => {
    const [isFocus, setFocus] = useState(false)
    const [isSelected, setSelected] = useState(false)

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            return
        }

        if (!onInput) {
            return
        }

        onInput(e.target.value)
    }

    const onEnter = () => {
        if (items.length === 0) {
            return
        }

        const item = items[0]
        handleSelect(itemValue ? item[itemValue] : item, item)
    }

    const wrapper = useRef(null)

    const handleClickOutside = () => {
        setFocus(false)
    }

    const handleClickInside = () => {
        setFocus(true)

        if (!onOpen) {
            return
        }

        onOpen()
    }

    useOnClickOutside(wrapper, handleClickOutside)

    const handleSelect = (_value: string, item: any) => {
        if (onSelect) onSelect(_value, item)

        setSelected(true)
        handleClickOutside()
    }

    useEffect(() => {
        if (!isFocus && !isSelected && onNotSelect) {
            onNotSelect()
        }

        setSelected(false)
    }, [isFocus])

    return (
        <div className={className}>
            {!!label && (
                <div className="mb-1 flex items-center">
                    <label className="text-sm text-neutrals-900 leading-normal font-medium block" htmlFor={id}>
                        {label}
                    </label>
                    {!!labelAppend && <div className="ml-1">{labelAppend}</div>}
                </div>
            )}
            <div className={fitToParentBox ? '' : 'relative'} ref={wrapper} onClick={handleClickInside}>
                <div
                    className={`flex border rounded-lg w-full items-center relative ${inputSizeMap[size]} ${
                        disabled
                            ? 'bg-neutrals-100 border-neutrals-400'
                            : !!variant
                            ? inputVariantMap[variant].input
                            : isFocus
                            ? 'border-primary-600 text-neutrals-900 bg-neutrals-light'
                            : `bg-neutrals-light text-neutrals-500 border-neutrals-400 ${
                                  value.length > 0 ? 'text-neutrals-800' : 'text-neutrals-500'
                              }`
                    }`}
                    style={{
                        width: width || 'auto'
                    }}>
                    {iconPosition === 'left' && (
                        <span className={!!icon ? 'mr-2.5 flex-shrink-0 flex items-center' : ''}>{icon}</span>
                    )}
                    <input
                        className={`box-content flex-grow flex-shrink min-w-0 outline-none bg-opacity-0 bg-transparent placeholder:text-neutrals-400 disabled:text-neutrals-400 ${
                            heightMap[size]
                        } ${fullWidthInput ? 'w-full' : ''}`}
                        style={{ fontSize: 'inherit' }}
                        disabled={disabled}
                        value={value}
                        onInput={handleInput}
                        placeholder={placeholder || ''}
                        id={id}
                        type="text"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') onEnter()
                        }}
                        autoComplete="off"
                    />
                    <div className="ml-2.5 w-4 h-4 flex-shrink-0">
                        {loading && <IconLoaderCircle className="w-4 h-4"></IconLoaderCircle>}
                    </div>
                    {iconPosition === 'right' && (
                        <span className={!!icon ? 'ml-2.5 flex-shrink-0 flex items-center' : ''}>{icon}</span>
                    )}
                </div>
                <Dropdown
                    isFocus={isFocus}
                    dropdownMinWidth={dropdownMinWidth}
                    prepend={prepend}
                    append={append}
                    parentRef={wrapper}>
                    {items.map((item, index) => (
                        <DropdownListItem
                            key={index}
                            isActive={value === item.value}
                            onClick={() => handleSelect(itemValue ? item[itemValue] : item, item)}>
                            {itemText ? item[itemText] : item}
                        </DropdownListItem>
                    ))}
                </Dropdown>
            </div>
            {captions.length > 0 && (
                <div
                    className={`flex mt-1 text-xs leading-normal ${
                        !!variant ? inputVariantMap[variant].captions : 'text-neutrals-500'
                    }`}>
                    {captions}
                </div>
            )}
        </div>
    )
}

const inputSizeMap = {
    sm: 'text-sm leading-none px-4 py-2.5',
    md: 'text-base leading-none px-4 py-2.5',
    lg: 'text-base leading-normal px-4 py-2.5'
}

const heightMap = {
    sm: 'h-3.5 py-0.5',
    md: 'h-4.5 py-0.5',
    lg: 'h-6.5 py-0.5'
}

const inputVariantMap = {
    success: {
        input: 'text-neutrals-900 border-green-500',
        captions: 'text-green-500'
    },
    error: {
        input: 'text-neutrals-900 border-red-500',
        captions: 'text-red-500'
    }
}
