import posthog from 'posthog-js'
import { IS_PROD, POSTHOG_API_KEY } from '~/constants'
import { EVENTS_NAME } from '~/types'
import { delayedCallback } from '~/utils'

/**
 * Initialize SDK
 */
const init = () => {
    posthog.init(POSTHOG_API_KEY || '', {
        api_host: 'https://app.posthog.com',
        autocapture: false,
        capture_pageview: false,
        capture_pageleave: false,
        opt_in_site_apps: true,
        debug: !IS_PROD
    })
}

/**
 * Identify user
 * @param userId user id
 * @param props profile properties
 * @param propsSetOnce profile properties but only set once. will be ignored if it has been defined
 */
const identify = (userId?: string, props?: Record<string, any>, propsSetOnce?: Record<string, any>) => {
    posthog.identify(userId, props, propsSetOnce)
}

/**
 * Send Posthog event
 * @param name event name
 * @param props event properties
 * @param userProps user proerties
 * @param userPropsSetOnce user properties but only set once. will be ignored if it has been defined
 * @param callback callback
 */
const capture = (
    name: EVENTS_NAME,
    props?: Record<string, any>,
    userProps?: Record<string, any>,
    userPropsSetOnce?: Record<string, any>,
    callback = () => false
) => {
    const properties: Record<string, any> = { ...props, platform: 'web' }

    if (userProps) {
        properties.$set = userProps
    }

    if (userPropsSetOnce) {
        properties.$setOnce = userPropsSetOnce
    }

    posthog.capture(name, properties)
    delayedCallback(callback)
}

const group = (type = 'business', key: string, props?: Record<string, any>) => {
    posthog.group(type, key, props)
}

/**
 * Reset user
 */
const reset = () => {
    posthog.reset()
}

const $posthog = {
    init,
    identify,
    capture,
    group,
    reset
}

export default $posthog
