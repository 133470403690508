import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const config: FirebaseOptions = {
    apiKey: 'AIzaSyAwewQ4ytMiaaiKz8F4YG25t6j3ubsDNN0',
    authDomain: 'kelola-bisnis.firebaseapp.com',
    projectId: 'kelola-bisnis'
}

const app = initializeApp(config)

export const firebaseAuth = getAuth()
